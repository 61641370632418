import React, { Component } from "react";
import MasterPage from "../components/layout/MasterPage";

export default class FaqScene extends Component {

    render() {

        return <MasterPage>
            <div class="post-content">
                <h2 class="mb">Tire suas<br />dúvidas<br />mais importantes</h2>
                <div class="col-sm-12">
                    <div class="container"><h3 id="o-que-a-easyfoto4u-oferece-">O que a EasyFotoBrasil oferece?</h3><p>Oferecemos o serviço de transformar fotos em Miniquadros, dando vida as suas fotos e ajudando você a decorar seus ambientes ! Isso dá a oportunidade de colocar na parede o registro de um momento, uma recordação que nos traz apresso e afetividade.</p><h3 id="como-utilizo-o-servi-o-">Como utilizo o serviço?</h3><p>O serviço é prestado através de um aplicativo instalado no seu celular.</p><h3 id="pago-alguma-coisa-para-baixar-o-aplicativo-">Pago alguma coisa para baixar o
                        aplicativo?</h3><p>Não. O download do aplicativo é gratuito.</p><h3 id="como-fa-o-para-comprar-miniquadros-">Como faço para comprar Miniquadros?</h3><p>Baixe o aplicativo e selecione algumas fotos (mínimo de 5). Ajuste as fotos e informe dados para entrega e pagamento. Após isso, basta finalizar compra.</p><h3 id="posso-enviar-os-miniquadros-para-outras-pessoas-como-um-presente-">Posso enviar os Miniquadros para outras pessoas como um presente?</h3><p>Claro! Basta informar no aplicativo o endereço completo para entrega e nome do destinatário.</p><h3
                        id="como-fa-o-o-pagamento-do-servi-o-">Como faço o pagamento do serviço?</h3><p>Até o momento só estamos aceitando cartões de crédito. É importante que o endereço informado nos dados para pagamento seja o endereço de correspondência do cartão de crédito informado para efetivação do pedido de compra.</p><h3 id="como-vejo-o-andamento-do-meu-pedido-">Como vejo o andamento do meu pedido?</h3><p>O usuário irá receber três emails que informarão o andamento de sua solicitação:</p>
                        <ul>
                            <li>a) Pedido de compra realizado;</li>
                            <li>b) Pedido de compra aprovado;</li>
                            <li>c) Pedido de compra enviado.</li>
                        </ul>
                        <h3 id="posso-obter-reembolso-se-algum-miniquadro-chegar-danificado-ou-se-eu-simplesmente-n-o-gostar-">Posso obter reembolso se algum Miniquadro chegar danificado ou se eu simplesmente não gostar?</h3><p>Nossa garantia de satisfação do cliente dá direito a reimpressão ou ao reembolso caso você não esteja 100% satisfeito.</p><h3 id="quais-fotos-posso-utilizar-">Quais fotos posso utilizar?</h3><p>Não há restrições, ao baixar o aplicativo será solicitado acesso as fotos contidas no seu dispositivo. Em breve, estaremos disponibilizando acesso a fotos de mídias sociais.</p>
                        <h3 id="quanto-custa-cada-quadro-">Quanto custa cada quadro?</h3><p>Nosso kit mínimo por pedido, com 5 Miniquadros, custa&nbsp;<span id="price">R$150,00</span>. Peças adicionais custam &nbsp;<span id="additional">R$27,00</span>&nbsp;quando adicionadas no mesmo pedido. Veja a tabela com simulações dos preços:</p>
                        <table>
                            <thead>
                            <tr>
                                <th>Quantidade</th>
                                <th>Kit Mínimo</th>
                                <th>Peça adicional</th>
                                <th>Valor do kit</th>
                            </tr>
                            </thead>
                            <tbody id="prices">
                            <tr>
                                <td>5 Miniquadros</td>
                                <td>5</td>
                                <td>0</td>
                                <td>R$150,00</td>
                            </tr>
                            <tr>
                                <td>6 Miniquadros</td>
                                <td>5</td>
                                <td>1</td>
                                <td>R$177,00</td>
                            </tr>
                            <tr>
                                <td>7 Miniquadros</td>
                                <td>5</td>
                                <td>2</td>
                                <td>R$204,00</td>
                            </tr>
                            <tr>
                                <td>8 Miniquadros</td>
                                <td>5</td>
                                <td>3</td>
                                <td>R$231,00</td>
                            </tr>
                            <tr>
                                <td>9 Miniquadros</td>
                                <td>5</td>
                                <td>4</td>
                                <td>R$258,00</td>
                            </tr>
                            <tr>
                                <td>10 Miniquadros</td>
                                <td>5</td>
                                <td>5</td>
                                <td>R$285,00</td>
                            </tr>
                            <tr>
                                <td>11 Miniquadros</td>
                                <td>5</td>
                                <td>6</td>
                                <td>R$312,00</td>
                            </tr>
                            <tr>
                                <td>12 Miniquadros</td>
                                <td>5</td>
                                <td>7</td>
                                <td>R$339,00</td>
                            </tr>
                            <tr>
                                <td>13 Miniquadros</td>
                                <td>5</td>
                                <td>8</td>
                                <td>R$366,00</td>
                            </tr>
                            <tr>
                                <td>14 Miniquadros</td>
                                <td>5</td>
                                <td>9</td>
                                <td>R$393,00</td>
                            </tr>
                            <tr>
                                <td>15 Miniquadros</td>
                                <td>5</td>
                                <td>10</td>
                                <td>R$420,00</td>
                            </tr>
                            </tbody>
                        </table>
                        <h3 id="tenho-como-comprar-pe-as-adicionais-sem-comprar-o-kit-m-nimo-">Tenho como comprar peças adicionais sem comprar o kit mínimo?</h3><p>Não, as peças adicionais só podem ser adquiridas junto com um kit mínimo.</p><h3 id="qual-a-medida-do-miniquadro-">Qual a medida do Miniquadro?</h3><p>Cada Miniquadro possui 20cm x 20cm. A profundidade do Miniquadro é de 0,5cm.</p><h3 id="existem-miniquadros-com-outras-medidas-">Existem Miniquadros com outras medidas?</h3><p>Ainda não, mas em breve teremos novidades.</p><h3
                            id="qual-a-cor-da-parte-lateral-e-da-parte-de-tr-s-do-miniquadro-">Qual a cor da parte lateral e da parte de trás do Miniquadro?</h3><p>A região da parte lateral (espessura 0,5cm) e as costas do Miniquadro são na cor preta.</p><h3 id="existe-op-o-de-cor-da-parte-lateral-ou-da-parte-de-tr-s-do-miniquadro-">Existe opção de cor da parte lateral ou da parte de trás do Miniquadro?</h3><p>Não, todos os quadros são elaborados com a mesma cor preta.</p><h3 id="em-quanto-tempo-meu-pedido-de-compras-chegar-no-endere-o-de-entrega-">Em quanto tempo meu pedido de compras chegará
                            no endereço de entrega?</h3><p>O tempo médio de produção é de até 7 dias úteis após a confirmação do pagamento. Acrescente a este prazo o tempo médio de entrega dos Correios.</p><h3 id="como-eu-colo-o-miniquadro-na-parede-">Como eu colo o Miniquadro na parede?</h3><p>Destaque o papel protetor de cada uma das 2 etiquetas adesivas contidas na parte de trás de cada peça e cole na parede.</p><h3 id="posso-colar-o-miniquadro-em-qualquer-tipo-de-parede-">Posso colar o Miniquadro em qualquer tipo de parede?</h3><p>Preferencialmente em paredes lisas. Não recomendamos colar
                            os miniquadros em paredes sujas, com umidade, papel de parede e com qualquer tipo de textura.</p><h3 id="uma-vez-colado-posso-remover-o-miniquadro-de-lugar-">Uma vez colado, posso remover o Miniquadro de lugar?</h3><p>Sim, o Miniquadro foi projetado para ser removível. Basta descolá-lo com cuidado da parede e colá-lo em outro lugar. Em algumas situações de exceção ao descolar o miniquadro o adesivo pode ficar colado na parede, neste caso basta descolar o adesivo da parede e coloca-lo novamente no Miniquadro. Você pode experimentar diferentes arranjos. Dê asas a sua
                            imaginação e criatividade.</p><h3 id="o-miniquadro-ir-estragar-minhas-paredes-">O Miniquadro irá estragar minhas paredes?</h3><p>Não, o material não provoca nenhum dano às paredes.</p><h3 id="o-miniquadro-pode-ser-colado-em-qualquer-ambiente-">O Miniquadro pode ser colado em qualquer ambiente?</h3><p>Sim, porém não recomendamos a exposição do Miniquadro em ambientes onde existam excesso de umidade, luz solar, calor excessivo ou exposiçào ao ar livre sem nenhum tipo de cobertura ou proteção.</p><h3 id="existe-uma-recomenda-o-m-nima-para-a-foto-">Existe uma
                            recomendação mínima para a foto?</h3><p>O arquivo deve possuir pelo menos 1200 por 1200 pixels. Fotos de baixa resolução resultarão em Miniquadros com pouca nitidez ou granularidade no resultado final. O Aplicativo irá informar ao usuário se a foto selecionada apresenta a qualidade mínima necessária. Entretanto, se o usuário ainda assim mantiver a seleção não poderemos nos responsabilizar pela qualidade do produto.</p><h3 id="posso-ajustar-minhas-fotos-">Posso ajustar minhas fotos?</h3><p>Claro! O aplicativo disponibilizada opção de edição de cada foto selecionada.
                            Selecione a foto que deseja ajustar e enquadre a foto como deseja que seja feita a impressão.</p><h3 id="quais-sistemas-operacionais-o-aplicativo-j-suporta-">Quais sistemas operacionais o aplicativo já suporta?</h3><p>Atualmente o aplicativo pode ser instalado nos sistemas operacionais Android e IOS.</p><h3 id="voc-s-entregam-em-todo-o-brasil-">Vocês entregam em todo o Brasil?</h3><p>Sim, entregamos em todos os lugares onde o Correios possui logística de entrega.</p><h3 id="voc-s-realizam-entrega-internacional-">Vocês realizam entrega internacional?</h3><p>Ainda
                            não, mas caso haja interesse entre em contato.</p></div>
                </div>
            </div>
        </MasterPage>
    }
}

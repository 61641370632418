import React, { Component } from "react";
import "../assets/sass/home.scss";

class Terms extends Component {

    render() {
        return (
            <div className="home">
                <header className="home_header">
                    <div className="container">
                        <h1 className="site-name"><span>EasyFotoBrasil</span></h1>
                        <h2 className="site-subtitle">Suas fotos como você nunca viu.<br/>Faça seus momentos serem muito mais reais <br /> que a tela de um smartphone.</h2>
                    </div>
                </header>
                <div class="terms">
                    <div class="container"><h2 id="-termos-de-servi-o-"><strong>Termos de Serviço</strong></h2><p><strong>CONTRATO DE PRESTAÇÃO DE SERVIÇOS ONLINE</strong></p><p>A<strong>"EASYFOTOBRASIL" EASYFOTOBRASIL APLICATIVO DE SERVIÇOS DE FOTOGRAFIA</strong>, sociedade limitada com sede à Rua Oliveira Rocha 33, 101, Jardim Botânico Cep 22241-070, na Cidade do Rio de Janeiro, RJ, inscrita no CNPJ/MF sob o nº 29.234.300/0001-17, e seus clientes ("USUÁRIOS"), ora ajustam os termos e condições que regem a prestação, por EASYFOTOBRASIL, de serviços online de impressão e compartilhamento de imagens
                        digitais (os "SERVIÇOS EASYFOTOBRASIL").</p><p><strong>1. OBJETO</strong></p><p>1.1. O objeto do Contrato é o estabelecimento das condições mediante as quais o USUÁRIO utilizará os SERVIÇOS da EASYFOTOBRASIL através de aplicativo instalado em dispositivo móvel do cliente.</p><p>1.2. Será qualificado como "USUÁRIO" todo aquele que preencher o cadastro na forma do item 2 abaixo.</p><p><strong>2. CADASTRO DO USUÁRIO</strong></p><p>2.1. Para a utilização de qualquer serviço disponibilizado no APLICATIVO, o USUÁRIO deverá fornecer dados pessoais solicitados nos campos de
                        preenchimento obrigatório durante a AQUISIÇÃO DO SERVIÇO. Ao adquirir o serviço, o USUÁRIO fornecerá informações verdadeiras, atualizadas e completas, declarando-se plenamente ciente de que a utilização indevida de dados de terceiros ou o fornecimento de informações falsas poderá caracterizar a prática de ilícitos penais e civis, sujeitando o infrator às penalidades previstas em lei.</p><p>2.2. O USUÁRIO obriga-se a manter todos os seus dados cadastrais devidamente atualizados, bem como a atender as solicitações de atualização do CADASTRO, sempre que requisitado pelo
                        EASYFOTOBRASIL.</p><p>2.3. O EASYFOTOBRASIL poderá utilizar os meios que entender necessários para, a qualquer momento, averiguar a veracidade das informações prestadas. Em sendo identificada qualquer irregularidade nos dados fornecidos pelo USUÁRIO, o EASYFOTOBRASIL poderá suspender imediatamente os serviços prestados àquele USUÁRIO.</p><p>2.4. Ao realizar uma AQUISIÇÃO DE SERVIÇO, o USUÁRIO autorizará o EASYFOTOBRASIL a: (i) compartilhar as informações de seu cadastro com empresas que com ele mantenham relação comercial e cujo acesso a tais informações seja importante para o
                        desenvolvimento e/ou manutenção dos SERVIÇOS EASYFOTOBRASIL, desde que tais parceiros e empresas aceitem integralmente o mesmo compromisso de sigilo e confidencialidade assumido por EASYFOTOBRASIL perante seus Usuários; (ii) utilizar arquivos temporários ("cookies") para a identificação dos Usuários; e (iii) enviar e-mails para o endereço eletrônico informado pelo USUÁRIO no ato de AQUISIÇÃO DE SERVIÇO no APLICATIVO. Os e-mails encaminhados pelo EASYFOTOBRASIL que tenham caráter técnico ou informativo não estarão sujeitos à recusa do USUÁRIO. O EASYFOTOBRASIL poderá também entrar em
                        contato com o USUÁRIO por telefone, a qualquer dia e hora, especificamente para solucionar questões técnicas referentes à prestação dos SERVIÇOS EASYFOTOBRASIL.</p><p><strong>3. OS SERVIÇOS EASYFOTOBRASIL</strong></p><p>3.1. Os SERVIÇOS EASYFOTOBRASIL consistem em:</p><p>3.1.1. Armazenamento e confecção de objetos mediante o envio composições fotográficas pelo USUÁRIO através do APLICATIVO EASYFOTOBRASIL. O armazenamento das imagens estará limitado ao tempo de execução dos SERVIÇOS prestados, não cabendo ao EASYFOTOBRASIL nenhum tipo de armazenamento por tempo ilimitado.</p><p><strong>4.
                        DA IMPRESSÃO E ENTREGA DAS FOTOGRAFIAS AO USUÁRIO</strong></p><p>4.1. O EASYFOTOBRASIL obriga-se a :</p><p>4.1.1. Permitir ao USUÁRIO enviar imagens digitalmente através do APLICATIVO, escolhendo as fotos que desejar para confecção dos objetos, sendo o pagamento realizado mediante cartão de crédito ou qualquer meio de pagamento disponibilizado no APLICATIVO;</p><p>4.1.2. A entrega do produto em domicílio será realizada por portador, no horário das 8h. às 20h., mediante protocolo devidamente assinado por responsável no endereço especificado para a entrega.</p><p>4.1.3. Serão
                        feitas, no máximo, 3 (três) tentativas de entrega. Caso não seja possível realizar a entrega do produto em razão da ausência de responsável para a assinatura do protocolo, ou por qualquer outro motivo independente da operação do EASYFOTOBRASIL, após a terceira tentativa, o pedido do USUÁRIO retornará ao endereço do remetente e aguardará um novo contato do USUÁRIO para nova confirmação do endereço de entrega.</p><p>4.1.4. O EASYFOTOBRASIL não se responsabiliza pela má-impressão das fotos decorrentes de falha técnica do equipamento do USUÁRIO ou da imperícia do USUÁRIO no momento
                        da utilização do do APLICATIVO.</p><p>4.1.5. As fotos enviadas para o EASYFOTOBRASIL sofrerão um tratamento digital de modo a otimizar o envio e armazenamento das fotos. Este tratamento não influi no resultado final dos formatos e objetos de impressão disponíveis no APLICATIVO EASYFOTOBRASIL.</p><p><strong>5.**</strong>SUPORTE TÉCNICO**</p><p>5.1. O EASYFOTOBRASIL oferecerá serviço de suporte técnico gratuito, exclusivamente por e-mail, com o objetivo de auxiliar o USUÁRIO na solução de problemas relacionados à utilização do APLICATIVO, pagamento e/ou quaisquer dúvidas referentes
                        aos SERVIÇOS EASYFOTOBRASIL;</p><p>5.2 Ao utilizar o suporte técnico, o USUÁRIO obriga-se a empregar linguagem adequada e termos que não sejam de forma alguma ameaçadores, obscenos, difamatórios, pejorativos, ou injuriosos, nem discriminatório em relação à raça, cor, credo ou nacionalidade do operador do suporte técnico, sob pena de rescisão imediata do Contrato, sem prejuízo de todas as demais medidas cabíveis.</p><p>5.3. A RESPONSABILIDADE do EASYFOTOBRASIL QUANTO AO SUPORTE TÉCNICO LIMITA-SE A EMPREENDER OS MELHORES ESFORÇOS COM VISTAS AO ATENDIMENTO SATISFATÓRIO DAS
                        PERGUNTAS E DÚVIDAS DO USUÁRIO REFERENTES AO OBJETO DESTE CONTRATO. O EASYFOTOBRASIL NÃO SE RESPONSABILIZA PELA SOLUÇÃO DAS REFERIDAS DÚVIDAS E PERGUNTAS NO MOMENTO DA CONSULTA AO SERVIÇO.</p><p><strong>6. PRAZO DO CONTRATO</strong></p><p>6.1. O presente Contrato tem vigência e eficácia por prazo indeterminado a contar da data da AQUISIÇÃO DO SERVIÇO e poderá ser rescindido por qualquer das Partes nos termos dos itens 7 e 9 abaixo.</p><p><strong>7. DAS OBRIGAÇÕES DO USUÁRIO</strong></p><p>7.1. Constitui obrigação do USUÁRIO:</p><p>7.1.1. Não enviar fotografias com conteúdo
                        pornográfico, discriminatório, profano, ofensivo, que atentem contra moral, a ética e aos bons costumes; o EASYFOTOBRASIL, ao seu exclusivo critério, poderá classificar o conteúdo como tal, reservando-se o direito de não realizar as impressões das imagens solicitadas e excluí-las de sua base de dados. Tomando todas as precauções legais possíveis.</p><p>7.1.2. Fornecer informações verdadeiras e manter seus dados cadastrais devidamente atualizados e completos, comunicando o EASYFOTOBRASIL sempre que houver qualquer alteração.</p><p>7.1.3. Não transmitir ou divulgar material
                        ilegal, difamatório, ameaçador, obsceno, prejudicial, injurioso ou praticar atos que possam ser considerados discriminatórios em relação a qualquer raça, cor, credo ou nacionalidade;</p><p>7.1.4. Não atentar contra o direito de personalidade e intimidade de terceiros divulgando imagens que causem ou possam causar qualquer espécie de constrangimento ou danos à reputação de tais pessoas;</p><p>7.1.5. Não compartilhar, difundir, transmitir ou colocar à disposição de terceiros quaisquer imagens que violem segredo industrial e/ou sigilo de dados;</p><p>7.1.6. Não transmitir
                        qualquer material cujo conteúdo viole direitos de propriedade intelectual e de imagens do EASYFOTOBRASIL e/ou de terceiros;</p><p>7.1.7. Não transmitir, dolosa ou culposamente, arquivos contendo vírus ou que de qualquer forma possam prejudicar os programas ou equipamentos do EASYFOTOBRASIL e/ou de terceiros;</p><p>7.1.8. Quaisquer fins ilegais, mediante transmissão ou obtenção de material em desacordo com a legislação brasileira, materiais que atentem contra a ordem pública, ou ainda, que caracterizem prática tipificada como crime, exemplificadamente, material relacionado ao
                        tráfico de drogas e/ou pedofilia;</p><p>7.1.9. Caso permita o compartilhamento de sua senha e/ou acesso com terceiros, assumir integral responsabilidade pelas ações e omissões praticadas por tais terceiros;</p><p>7.1.10. Assumir integral responsabilidade por todos os efeitos decorrentes de quaisquer alegações por quaisquer terceiros, de violação de direitos e eventuais danos ou prejuízos decorrentes do descumprimento de quaisquer de suas obrigações nos termos deste Contrato, isentando o EASYFOTOBRASIL, seus sócios, empregados e contratados de qualquer responsabilidade
                        decorrente de tais alegações, inclusive quanto a cópias e retransmissões de imagens por tais terceiros sem o conhecimento ou consentimento do USUÁRIO;</p><p>7.1.11.<strong>O USUÁRIO DEVE PRESERVAR SEMPRE SUAS IMAGENS ORIGINAIS OU EFETUAR UMA CÓPIA (BACK-UP) EM SEU SISTEMA PESSOAL OU EM MÍDIAS DIGITAIS DE SUA RESPONSABILIDADE. O SERVIÇO EASYFOTOBRASIL NÃO DEVE SER UTILIZADO, EM NENHUMA HIPÓTESE, PARA ARMAZENAMENTO DE SEUS ARQUIVOS PESSOAIS</strong>.</p><p><strong>8. MODIFICAÇÃO NOS TERMOS E CONDIÇÕES</strong></p><p>8.1. O EASYFOTOBRASIL poderá modificar os termos e condições a
                        qualquer tempo, por qualquer razão e sem comunicação prévia, incluindo a extinção do serviço ou parte dos serviços, devendo o USUÁRIO consultar esta página periodicamente.</p><p>8.2. O EASYFOTOBRASIL poderá oferecer serviços adicionais aos ora previstos, onerosos ou gratuitos, mediante a celebração com o USUÁRIO de termo aditivo a este Contrato.</p><p>8.3. O serviço de armazenamento poderá ser limitado a qualquer tempo, mediante notificação prévia ao USUÁRIO.</p><p><strong>9. RESCISÃO</strong></p><p>9.1. O presente Contrato poderá ser rescindido por qualquer das Partes a
                        qualquer tempo, desde que notifique a outra Parte, por e-mail, com 30 (trinta) dias de antecedência. O EASYFOTOBRASIL considerará rescindido o Contrato pelo USUÁRIO caso este deixe de realizar uma compra no APLICATIVO pelo menos uma vez a cada período de 6 (seis) meses a contar da data do CADASTRO, podendo remover todas as informações, catálogos de endereços e imagens do USUÁRIO. Cada compra subsequente dará início a um novo período de 6 (seis) meses.</p><p>9.2.<strong>O EASYFOTOBRASIL poderá rescindir o Contrato a qualquer tempo e sem comunicação prévia no caso de
                        descumprimento contratual por parte do USUÁRIO</strong>.</p><p><strong>10. DISPOSIÇÕES GERAIS</strong></p><p>10.1. O Contrato é regido pelas leis da República Federativa do Brasil e as Partes elegem o Foro Central da Comarca do Estado do Rio de Janeiro para dirimir quaisquer controvérsias dele decorrentes.</p></div>
                </div>
                <footer>
                    <div class="row centered">
                        <h2>Entre em contato conosco</h2>
                        <h5><a href="mailto:atendimento@easyfotobrasil.com">atendimento@easyfotobrasil.com</a></h5>
                        <p>

                            <a href="https://www.instagram.com/easyfoto_brasil/" target="_blank" rel="noopener noreferrer"><i class="ion-social-instagram"></i></a>

                        </p>
                        <h6 class="rights">EASYFOTOBRASIL - CNPJ:29.234.300/0001-17</h6>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Terms;

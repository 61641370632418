import React from "react";
import MasterPage from "../components/layout/MasterPage";
import { DefaultPanel } from "../components/Panels";
import InputCupom from "../components/InputCupom";
import InputField from "../components/InputField";
import { DefaultButton } from "../components/DefaultButton";
import DataCard from "../components/DataCard";
import ConfigService from "../easyfoto/services/ConfigService";
import OrderService from "../easyfoto/services/OrderService";
import FormValidator from "../easyfoto/FormValidator";
import Order from "../easyfoto/Order";
import Breadcrumb from "../components/Breadcrumb";
import TextAreaField from "../components/TextField";

export default class PaymentScene extends FormValidator {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			searchingCoupon: false,
			sendingPayment: false,
			fullDiscount: false,
			showDiscount: false,
			couponResponse: null,
			hasPhotosPendingUpload: false,
			installments: 1,
			coupon: "",
			gift: false,
			giftMessage: "Aproveite seu presente!"
		};

		this.checkUploadPhotos = setInterval(() => {
			let hasPhotosPendingUpload = Order.hasPhotosPendingUpload();
			this.setState(
				{
					hasPhotosPendingUpload: hasPhotosPendingUpload
				},
				() => {
					console.debug("hasPhotosPendingUpload", hasPhotosPendingUpload);
					if (!hasPhotosPendingUpload) {
						clearInterval(this.checkUploadPhotos);
					}
				}
			);
		}, 5000);
	}

	componentDidMount() {
		window.kondutoIdentify(Order.cartId);
		window.kondutoSendEvent("page", "Checkout");

		window.scrollTo(0, 0);

		const coupon = localStorage.getItem("r");

		if (coupon) {
			this.setState({ coupon });
		}

		var preState = {};

		ConfigService.loadCachedConfigsValue(Order).then(() => {
			ConfigService.getCustomerDataFromStorage()
				.then(customerData => {
					Order.personalData = customerData.personalData || {};
					Order.address = customerData.address;
					Order.freightOption = customerData.freightOption;

					Order.loadPhotosFromDB()
						.then(photos => {
							Order.recoverPendingUploadPhotos().then(() => {
								console.debug("recoverPendingUploadPhotos.finished");
								this.setState({
									hasPhotosPendingUpload: Order.hasPhotosPendingUpload()
								});
							});

							if (Order.photos.length > 0) {
								if (Order.address) {
									const addr = Order.address;
									preState.city = addr.city;
									preState.address = addr.address;
									preState.province = addr.province;
									preState.state = addr.state;
									preState.zipCode = addr.zipCode;
									preState.number = addr.number;
									preState.complement = addr.complement;
									preState.contactName = addr.contactName;
									preState.addressFound = true;
									preState.freightOptions = Order.freightOptions;
									preState.searchingZipCode = false;
								}
								preState.loading = false;
								this.setState(preState);
							} else {
								window.location.href = "/";
							}
						})
						.catch(() => {
							window.location.href = "/";
						});
				})
				.catch(() => {});
		});
	}

	componentWillUnmount() {
		clearInterval(this.checkUploadPhotos);
	}

	setPaymentName = paymentName => {
		this.setState({
			paymentName: paymentName
		});
	};

	setPaymentDocument = document => {
		this.setState({
			document: document
		});
	};

	setPaymentEmail = email => {
		this.setState({
			email: email
		});
	};

	setPaymentEmailConfirm = confirmEmail => {
		this.setState({
			confirmEmail: confirmEmail
		});
	};

	setPaymentCoupon = coupon => {
		this.setState({
			coupon
		});
	};

	validateCoupon = () => {
		Order.coupon = this.state.coupon;
		Order.couponResponse = null;

		console.debug(Order.coupon);

		if (typeof Order.coupon === "undefined" || !Order.coupon) {
			this.setState({
				showDiscount: false,
				searchingCoupon: false,
				couponResponse: null
			});

			return;
		}

		this.setState(
			{
				searchingCoupon: true
			},
			() => {
				OrderService.validateCoupon(Order.coupon)
					.then(res => {
						Order.couponResponse = res;
						console.debug("CartPayment: CouponResponse", res);
						this.setState({ loading: false });
						Order.couponResponse = res.data;
						if (Order.couponResponse) {
							if (Order.isFullDiscount()) {
								this.setState({
									fullDiscount: true
								});
							} else {
								//this.doCardValidations();
								this.setState({ fullDiscount: false });
							}
							this.setState({
								showDiscount: true,
								searchingCoupon: false,
								couponResponse: Order.couponResponse
							});
						} else {
							this.setState({
								showDiscount: false,
								searchingCoupon: false,
								couponResponse: "none"
							});
						}
					})
					.catch(err => {
						console.debug("error", err);
						this.setState({ searchingCoupon: false });
						Order.couponResponse = null;
						//this.doCardValidations();
						this.setState({
							showDiscount: false
						});
					});
			}
		);
	};

	setPaymentCard = value => {
		this.setState({
			cardNumber: value.cardNumber,
			cardHolder: value.cardHolder,
			expirationMonth: value.expirationMonth,
			expirationYear: value.expirationYear,
			CVV: value.CVV.replace(/_/g, ""),
			installments: Number(value.installments)
		});
	};

	validateEmailConfirm = (value, confirmTo) => {
		const validationResult = this.validateEmail(value);
		if (validationResult.valid) {
			if (value === confirmTo) {
				return { valid: true };
			} else {
				return {
					valid: false,
					code: "email.confirm.error",
					message: "Email não coincide",
					validation: "emailConfirm"
				};
			}
		} else {
			return validationResult;
		}
	};

	forwardAvailable() {
		const validPaymentName = this.validateFullName(this.state.paymentName)
			.valid;
		const validPaymentDocument = this.validateCpf(this.state.document).valid;
		const validPaymentEmail = this.validateEmail(this.state.email).valid;
		const validPaymentEmailConfirmation = this.validateEmailConfirm(
			this.state.confirmEmail,
			this.state.email
		).valid;
		const validCardNumber = this.validateRequired(this.state.cardNumber).valid;
		const validCardHolder = this.validateRequired(this.state.cardHolder).valid;
		const validCardMonth = this.validateRequired(this.state.expirationMonth)
			.valid;
		const validCardYear = this.validateRequired(this.state.expirationYear)
			.valid;
		const validCardCVV = this.validateRequired(this.state.CVV).valid;
		return (
			validPaymentName &&
			validPaymentDocument &&
			validPaymentEmail &&
			validPaymentEmailConfirmation &&
			(this.state.fullDiscount ||
				(validCardNumber &&
					validCardHolder &&
					validCardMonth &&
					validCardYear &&
					validCardCVV))
		);
	}

	closeOrder = () => {
		console.debug("Payment:CloseOrder");

		if (Order.hasPhotosPendingUpload()) {
			Order.startUpload();
		} else {
			Order.personalData.fullName = this.state.paymentName;
			Order.personalData.cpf = this.state.document;
			Order.personalData.email = this.state.email;
			Order.installments = this.state.installments;
			Order.paymentData = {
				number: this.state.cardNumber,
				name: this.state.cardHolder,
				expirationMonth: this.state.expirationMonth,
				expirationYear: this.state.expirationYear,
				CVV: this.state.CVV
			};
			Order.gift = this.state.gift;
			Order.giftMessage = this.state.giftMessage;

			this.setState({ sendingPayment: true });

			OrderService.closeOrder(resp => {
				this.setState(
					{
						sendingPayment: false
					},
					() => {
						console.debug("Payment:CloseOrderResponse", resp);

						if (resp) {
							Order.paymentApiResult = resp.data;

							Order.reset(true);
							Order.persistPhotos().finally(() => {
								window.location.href = "/success/" + resp.data.orderId;
							});
						} else {
							window.alert("Erro ao fechar pedido");
						}
					}
				);
			});
		}
	};

	render() {
		let sendingPayment = this.state.sendingPayment === true;

		return (
			<MasterPage loading={this.state.loading}>
				<Breadcrumb step={"payment"} />
				<h1 className="page-title">Dados de Pagamento</h1>
				<div className="delivery">
					<DefaultPanel className="panel-default-4" title="Dados do comprador">
						<InputField
							label="Nome completo"
							placeholder="Ex: João da Silva"
							onChange={this.setPaymentName.bind(this)}
							validation={this.validateFullName}
						/>
						<InputField
							mask="999.999.999-99"
							label="CPF"
							placeholder="123.456.789-10"
							onChange={this.setPaymentDocument.bind(this)}
							validation={this.validateCpf}
						/>
						<InputField
							type="email"
							label="Email"
							placeholder="joao@email.com"
							onChange={this.setPaymentEmail.bind(this)}
							validation={this.validateEmail}
						/>
						<InputField
							type="email"
							label="Digite o email novamente"
							placeholder="joao@email.com"
							onChange={this.setPaymentEmailConfirm.bind(this)}
							validation={value => {
								return this.validateEmailConfirm(value, this.state.email);
							}}
						/>
					</DefaultPanel>
					<DefaultPanel className="panel-default-4" title="Cupom de desconto">
						<InputCupom
							title="Código do cupom"
							value={this.state.coupon}
							loading={this.state.searchingCoupon}
							onChange={this.setPaymentCoupon.bind(this)}
							onSubmit={this.validateCoupon.bind(this)}
						/>
						{this.state.couponResponse !== null ? (
							<React.Fragment>
								{this.state.couponResponse === "none" ? (
									<div className="coupon-box coupon-invalid">
										<p>Cupom inválido</p>
									</div>
								) : (
									<div className="coupon-box">
										<p>Ótimo!!</p>
										<p>Este cupom lhe garante:</p>
										{this.state.couponResponse.discountType === "PERCENT" ? (
											<p className="coupon-value">
												{this.state.couponResponse.value}% de desconto
											</p>
										) : null}
										{this.state.couponResponse.discountType === "ABSOLUTE" ? (
											<p className="coupon-value">
												R${this.state.couponResponse.value} de desconto
											</p>
										) : null}
									</div>
								)}
							</React.Fragment>
						) : null}
						<div style={{ marginTop: 16 }}>
							<InputField
								type="checkbox"
								label="Este item é um presente"
								checked={this.state.gift}
								onChange={value => {
									this.setState({ gift: value });
								}}
							/>
						</div>

						{this.state.gift && (
							<TextAreaField
								style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}
								rows={5}
								value={this.state.giftMessage}
								onChange={value => {
									this.setState({ giftMessage: value });
								}}
								placeholder="Digite uma mensagem para a pessoa aqui"
							/>
						)}
					</DefaultPanel>
					<DefaultPanel className="panel-default-4" title="Dados do cartão">
						{this.state.fullDiscount ? (
							<div>
								<img
									className="form-gift"
									src={require("../assets/images/gift.png")}
									alt="Cupom de 100%"
								/>
							</div>
						) : (
							<DataCard
								onChange={this.setPaymentCard.bind(this)}
								cardNumberValidation={this.validateRequired}
								cardHolderValidation={this.validateRequired}
								cardCVVValidation={this.validateRequired}
							/>
						)}
					</DefaultPanel>
				</div>
				<div className="button-panel">
					{this.forwardAvailable() ? (
						this.state.hasPhotosPendingUpload ? (
							<DefaultButton
								disabled={true}
								loading={true}
								text="Subindo últimas fotos, por favor aguarde..."
							/>
						) : (
							<DefaultButton
								text="Concluir Pedido"
								onClick={this.closeOrder.bind(this)}
								disabled={sendingPayment}
								loading={sendingPayment}
							/>
						)
					) : (
						<DefaultButton disabled={true} text="Concluir Pedido" />
					)}

					<div style={{ padding: "5px" }}>
						Ao concluir o pedido você concorda com nossos termos e aceita
						receber nossos envios de emails.
					</div>
				</div>
			</MasterPage>
		);
	}
}

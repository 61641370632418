import React, { Component } from 'react';
import MasterPage from "../components/layout/MasterPage";
import { DefaultPanel } from '../components/Panels';
import InputField from '../components/InputField';
import {DefaultButton} from "../components/DefaultButton"

class PersonalData extends Component {
    render() {
        return (
            <MasterPage>
                
                <h1 className="page-title">Dados Pessoais</h1>
                <div className="personal-data">
                    <DefaultPanel title="Dados do comprador">
                        <InputField type="text" label="Nome completo" value="João da Silva" />
                        <InputField type="text" mask="999.999.999-99" label="CPF" value="123.456.789-10" />
                        <InputField type="email" label="Email" value="joao@email.com" readonly="readonly" />
                        
                    </DefaultPanel>
                    <DefaultPanel title="Redefinir senha">
                        <InputField type="password" label="Senha" value="*********" />
                        <InputField type="password" label="Nova senha" />
                        <InputField type="password" label="Confirmar nova senha" />
                    </DefaultPanel>
                
                </div>
                <div className="button-next">
                    <DefaultButton
                                   onChange={(e) => { console.debug(e);}}
                                   text="Salvar"/>
                </div>
            </MasterPage>
            
        );
    }
}

export default PersonalData;
import React, { Component } from 'react';
import MasterPage from "../components/layout/MasterPage";
import InputField from '../components/InputField';
import {DefaultButton} from "../components/DefaultButton";
import { Link } from "react-router-dom";

class Login extends Component {
    render() {
        return (
            <MasterPage>
                
                <h1 className="page-title">Autentique-se</h1>
                <div className="login-box">
                    <div className="login-content">
                        <InputField label="Email" type="text" placeholder="joao@email.com" className="form-input login" />
                        <InputField label="Senha" type="password" placeholder="*************" className="form-input login" />
                        <Link to="/lost-pass" className="login-reset">Esqueceu a senha? Clique aqui</Link>
                        <DefaultButton text="Entrar" />
                        <Link to="/register" className="login-register">Registre-se aqui</Link>
                        </div>
                </div>
            </MasterPage>
            
        );
    }
}

export default Login;
import cfg from '../Config';
import * as blobUtil from 'blob-util';

export default class ResizeAndCropSevice {

    static BASE_64_IMAGE_PROTOCOL = "data:image/jpeg;base64,";

    /**
     * Resize an image to default image size in config
     * @param {Image} image
     * @returns {*}
     */
    static resizeToDefaultSize(image) {

        function roundUp(num) {
            return Math.ceil(num * 100) / 100
        }

        let widthToResize = image.width;
        let heightToResize = image.height;

        console.debug("ResizeAndCropSevice: Tamanho Original da Imagem", widthToResize, heightToResize);

        if (widthToResize < heightToResize) {
            let ratio = roundUp(heightToResize / widthToResize);
            widthToResize = cfg.IMAGEOPTIONS.width;
            heightToResize = ratio * cfg.IMAGEOPTIONS.height;
        } else {
            let ratio = roundUp(widthToResize / heightToResize);
            widthToResize = ratio * cfg.IMAGEOPTIONS.width;
            heightToResize = cfg.IMAGEOPTIONS.height;
        }

        console.debug("ResizeAndCropSevice: Tamanho Transformado da Imagem", widthToResize, heightToResize);

        return ResizeAndCropSevice.resizeImage(image, widthToResize, heightToResize)

    }

    /**
     * Takes a centralized cropped image in Base64 String.
     * @async
     * @param {String} Blob file
     * @returns {Promise<Object>}
     */
    static getCentralizedCroppedImage(file) {
        return new Promise((resolve, reject) => {
            ResizeAndCropSevice.blobToImage(file).then((imageSource) => {

                let widthToResize = imageSource.width;
                let heightToResize = imageSource.height;

                if(widthToResize < heightToResize){
                    let ratio = this.roundUp(heightToResize/widthToResize);
                    widthToResize = cfg.IMAGEOPTIONS.width;
                    heightToResize = ratio * cfg.IMAGEOPTIONS.height;
                }else{
                    let ratio = this.roundUp(widthToResize/heightToResize);
                    widthToResize = ratio * cfg.IMAGEOPTIONS.width;
                    heightToResize = cfg.IMAGEOPTIONS.height;
                }

                let resizedImage = ResizeAndCropSevice.imageToCanvas({
                    image: imageSource,
                    origin_x: 0,
                    origin_y: 0,
                    origin_width: imageSource.width,
                    origin_height: imageSource.height,
                    destination_x: 0,
                    destination_y: 0,
                    destination_width: widthToResize,
                    destination_height: heightToResize,
                    canvasWidth: widthToResize,
                    canvasHeight: heightToResize
                });


                let width = Math.min(cfg.IMAGEOPTIONS.width, resizedImage.width);
                let height = Math.min(cfg.IMAGEOPTIONS.height, resizedImage.height);

                let x = (resizedImage.width - width === 0) ? 0 : (resizedImage.width - width) / 2;
                let y = (resizedImage.height - height === 0) ? 0 : (resizedImage.height - height) / 2;

                let cropData = {
                    offset: {x: x < 0 ? 0 : x, y: y < 0 ? 0 : y},
                    size: {width: width, height: height}
                };

                this.canvasToBlob(ResizeAndCropSevice.imageToCanvas({
                    image: resizedImage,
                    origin_x: cropData.offset.x,
                    origin_y: cropData.offset.y,
                    origin_width: cropData.size.width,
                    origin_height: cropData.size.height,
                    destination_x: 0,
                    destination_y: 0,
                    destination_width: cfg.IMAGEOPTIONS.width,
                    destination_height: cfg.IMAGEOPTIONS.width,
                    canvasWidth: cfg.IMAGEOPTIONS.width,
                    canvasHeight: cfg.IMAGEOPTIONS.width
                }), 'image/jpeg').then((blobRef) => {
                    resolve({blobRef: blobRef, originalWidth: imageSource.width, originalHeight: imageSource.height});
                });
            });
        })

    }

    static canvasToBlob(canvas){
        return new Promise((resolve, reject) => {
            blobUtil.canvasToBlob(canvas).then((blobRef) => {
                resolve(blobRef);
            });
        });
    }

    /**
     * Change Size of an image
     * @param {Element} image
     * @param {Number} width
     * @param {Number} height
     * @returns {String} Base64 String Image
     */
    static resizeImage(image, width, height) {
        console.debug("ResizeAndCropSevice: Resizing image to " + width + "x" + height);
        let canvasElement = ResizeAndCropSevice.imageToCanvas({
            image: image,
            origin_x: 0,
            origin_y: 0,
            origin_width: image.width,
            origin_height: image.height,
            destination_x: 0,
            destination_y: 0,
            destination_width: width,
            destination_height: height,
            canvasWidth: width,
            canvasHeight: height
        })
        return canvasElement.toDataURL("image/jpeg", cfg.IMAGEOPTIONS.quality);
    }

    /**
     * Transform File to Blob
     * @param {File} file - Uploaded File
     * @returns {string} - Browser URL of Object
     */
    static fileToBlobRef(file) {
        let url = window.URL || window.webkitURL;
        return url.createObjectURL(file);
    }

    /**
     * Get Base64String from Image Element
     * @param {Element} imageElement
     * @return {String} Base64 String Image
     */
    static imageToB64I(imageElement) {
        const height = imageElement.height;
        const width = imageElement.width;
        return ResizeAndCropSevice.imageToCanvas({
            image: imageElement,
            origin_x: 0,
            origin_y: 0,
            origin_width: width,
            origin_height: height,
            destination_x: 0,
            destination_y: 0,
            destination_width: width,
            destination_height: height,
            canvasWidth: width,
            canvasHeight: height
        }).toDataURL("image/jpeg", cfg.IMAGEOPTIONS.quality);
    }

    /**
     * Transform Blob to Base64 Image
     * @async
     * @param {Blob} blob - Blob file
     * @returns {Promise<Base64String>}
     */
    static blobToB64I(blob) {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.src = blob;
            image.onload = () => {
                resolve(this.imageToB64I(image));
            }
        })
    }

    /**
     * Change Object to Canvas
     * @param params
     * @returns {Element}
     */
    static imageToCanvas(params) {
        let canvas = document.createElement('canvas');
        canvas.width = params.canvasWidth;
        canvas.height = params.canvasHeight;
        let ctx = canvas.getContext('2d', {alpha: false});
        ctx.drawImage(params.image, params.origin_x, params.origin_y, params.origin_width, params.origin_height, params.destination_x, params.destination_y, params.destination_width, params.destination_height);
        return canvas;
    }

    /**
     * Chanve Canvas to Image Element
     * @async
     * @param {Element} canvas
     * @returns {Promise<Element>}
     */
    static canvasToImage(canvas) {
        return new Promise((result, reject) => {
            let image = new Image();
            image.src = canvas.toDataURL("image/jpeg");
            image.onload = () => {
                result(image);
            }
        })
    }

    /**
     * Change Base 64 Image to Image Element
     * @async
     * @param base64String
     * @returns {Promise<Element>}
     */
    static Base64ImageToImage(base64String) {
        return new Promise((result, reject) => {
            let image = new Image();
            image.src = base64String;
            image.onload = () => {
                result(image);
            }
        })
    }

    static blobToImageTag(blob){
        var blobURL = blobUtil.createObjectURL(blob);
        var image = document.createElement('img');
        image.src = blobURL;
        return image;
    }

    static blobToImage(blob){
        return new Promise((result, reject) => {
            let image = new Image();
            blobUtil.blobToBase64String(blob).then((dataBase64) => {
                image.src = ResizeAndCropSevice.BASE_64_IMAGE_PROTOCOL + dataBase64;
                image.onload = () => {
                    result(image);
                }
            });

        })
    }

    static blobToBase64String(blob){
        return new Promise((result, reject) => {
            blobUtil.blobToBase64String(blob).then(function (base64String) {
                return result(ResizeAndCropSevice.BASE_64_IMAGE_PROTOCOL + base64String);
            }).catch(function (err) {
                // error
            });
        });

    }

    static roundUp(num){
        return Math.ceil(num * 100) / 100
    }

    /**
     * Calculate min sizes of photo
     * @param {number} width - Image width
     * @param {number} height - Image Height
     * @returns {boolean} - True if image is low resolution
     */
    static isLowResolution(width, height) {
        return (width < cfg.IMAGEOPTIONS.width || height < cfg.IMAGEOPTIONS.height );
    }
}
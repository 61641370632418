import React, { Component } from "react";

export default class LoadingPanel extends Component {
    render() {
        return (
            <div className="starting-panel">
                <p>Aguarde, processando suas informações.</p>
            </div>
        );
    }
}

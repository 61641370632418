/**
 * Class representing a Photo
 */
import ResizeAndCropService from "./services/ResizeAndCropService";

class Photo {

    /**
     * Photo Wrapper, all images must be Base64 Strings
     * @param {string} index - An identification string
     * @param {string} [fileName] - Name of file uploaded
     * @param {string} [originalImage] - Blob original
     * @param {string} [croppedBlob] - Blob cropped
     * @param {string} [originalWidth] - Width of original file
     * @param {string} [originalHeight] - Height of original file
     */
    constructor(index, fileName, originalImage, croppedBlob, originalWidth, originalHeight) {

        console.debug('Photo: New Photo');

        this.index = index;
        this.originalFileName = fileName;
        this.originalPhoto = originalImage;
        this.croppedPhoto = croppedBlob;
        this.userCropped = false;

        this.S3Saved = false;
        this.S3Response = null;
        this.lastUploadDate = null;
        this.quantity = 1;

        this.originalWidth = originalWidth;
        this.originalHeight = originalHeight;

        this.lowResolution = ResizeAndCropService.isLowResolution(originalWidth, originalHeight);

    }

}

export default Photo;
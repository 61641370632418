import React, { Component } from 'react';

export class DefaultPanel extends Component {
    render() {
        return (
            <div className={"panel-default " + this.props.className}>
                <h3 className="panel-default-title">{this.props.title}</h3>
                <div className="panel-default-content">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export class HorizontalPanel extends Component {
    render() {
        return (
            <div className="panel-horizontal">{this.props.children}</div>
        );
    }
}

export class VerticalPanel extends Component {
    render() {
        return (
            <div className="panel-vertical">{this.props.children}</div>
        );
    }
}


export class PhotoList extends Component{
    render(){
        return(
            <div className="panel-photolist">{this.props.children}</div>
        );
    }
}
import ConfigService from "./services/ConfigService";
import UploadService from "./services/UploadService";
import cfg from "./Config";

import Storage from "./Storage";
const uuid = require("uuid/v4");

class Order {
	constructor() {
		this.showOnlyHighRes = true;
		this.reset();
	}

	getItensQuantity() {
		if (typeof this.photos !== "undefined" && this.photos.length > 0) {
			return this.photos.map(el => el.quantity).reduce((acc, cur) => acc + cur);
		}
		return 0;
	}

	getSelectedProduct() {
		if (typeof this.configs !== "undefined" && this.configs.selectedOption) {
			return this.configs.selectedOption;
		}

		return {
			name: "--",
			startQuantity: cfg.MIN_UNITS
		};
	}

	getSelectedProductName() {
		if (typeof this.configs !== "undefined" && this.configs.selectedOption) {
			return this.configs.selectedOption.name;
		}
		return "--";
	}

	calcSubtotalPrice() {
		var quantity = this.getItensQuantity();
		var baseCfg = this.configs.selectedOption;
		var price = baseCfg && baseCfg.startPrice;
		var startQuantity = baseCfg && baseCfg.startQuantity;

		if (startQuantity < quantity) {
			price += (quantity - startQuantity) * baseCfg.price;
		}

		return this.round(price);
	}

	calcTotalPrice() {
		return (
			this.round(
				this.calcSubtotalPrice() +
					(this.freightOption != null ? this.freightOption.value : 0)
			) - this.round(this.discountPrice())
		);
	}

	calcFreightPrice() {
		return this.freightOption != null ? this.freightOption.value : 0;
	}

	discountPrice() {
		var c = this.couponResponse;
		if (c && c.status === "AVAILABLE") {
			if (c.discountType === "ABSOLUTE") {
				return c.value;
			} else if (c.discountType === "PERCENT") {
				if (this.isFullDiscount())
					return this.calcSubtotalPrice() + this.calcFreightPrice();

				return (this.calcSubtotalPrice() * c.value) / 100;
			}
		} else {
			return 0;
		}
	}

	isFullDiscount() {
		var c = this.couponResponse;
		if (c && c.status === "AVAILABLE") {
			if (c.discountType === "PERCENT") {
				return c.value === 100;
			}
		}

		return false;
	}

	hasDiscount() {
		var c = this.couponResponse;
		return c && c.status === "AVAILABLE";
	}

	round(price) {
		return Math.floor(price * 100) / 100;
	}

	getIndex() {
		this.nextIndex++;
		return this.nextIndex;
	}

	uploadCroppedPhoto(photo) {
		console.debug("Photo: iniciando envio de foto única que foi cropada");
		photo.S3Saved = false;
		photo.S3Response = null;
		this.uploadPhoto(photo)
			.then(val => {
				console.debug("Photo: Foto enviada com sucesso");
			})
			.catch(val => {
				console.debug("Photo: Erro ao enviar foto");
				console.debug(val);
			});
	}

	loadProductsFromDB() {
		ConfigService.loadCachedConfigsValue(this, () => {});
	}

	loadPhotosFromDB() {
		return new Promise((resolve, reject) => {
			console.debug("Order:loadPhotosFromDB: photosLoaded", this.photos);

			if (
				typeof this.photos === "undefined" ||
				!this.photos ||
				this.photos.length === 0
			) {
				console.debug(
					"Order:loadPhotosFromDB: não existem fotos no modelo, carregando do banco de dados"
				);
				Storage.loadFromDB("photos")
					.then(photos => {
						this.photos = photos ? photos : [];
						resolve(this.photos);
					})
					.catch(() => {
						console.debug("IndexedDB error");
						reject(this.photos);
					});
			} else {
				resolve(this.photos);
			}
		});
	}

	startUpload() {
		return new Promise((resolve, reject) => {
			if (typeof this.photos !== "undefined" && this.photos) {
				this.uploadingBatch = true;

				if (typeof this.uploadStart == "function") {
					this.uploadStart();
				}

				let promisesUpload = [];
				this.photos.forEach(photo => {
					promisesUpload.push(this._uploadPhotoUnit(photo));
				});

				Promise.all(promisesUpload)
					.then(() => {
						console.debug("upload.allPhotos.success");
					})
					.catch(e => {
						console.debug("errorUpload.some", e);
					})
					.finally(() => {
						this.uploadingBatch = false;
						this.uploadHasFinished = true;
						this.persistPhotos();

						if (typeof this.uploadFinish == "function") {
							this.uploadFinish();
						}

						resolve();
					});
			} else {
				resolve();
			}
		});
	}

	_uploadPhotoUnit(photo) {
		this.uploadHasFinished = false;

		return new Promise((resolve, reject) => {
			if (photo.S3Saved) {
				try {
					console.debug(
						"Order:Photo.alreadyUploaded",
						photo.lastUploadDate,
						photo.S3Response.body.postResponse.location
					);
				} catch (e) {}

				resolve();
			} else {
				this.uploadPhoto(photo)
					.then(response => {
						resolve();
					})
					.catch(e => {
						reject(e);
					});
			}
		});
	}

	onUploadHasStarted(fn) {
		this.uploadStart = fn;
		return this;
	}

	onUploadHasFinished(fn) {
		this.uploadFinish = fn;
		return this;
	}

	hasPhotosPendingUpload() {
		return (
			typeof this.photos !== "undefined" &&
			this.photos &&
			this.photos.some(el => {
				return typeof el.S3Saved === "undefined" || !el.S3Saved;
			})
		);
	}

	uploadPhoto(photo) {
		return new Promise((resolve, reject) => {
			var lastUploadDate = new Date();
			photo.lastUploadDate = new Date(lastUploadDate.getTime());
			const nameOnBucket =
				"webapp/" +
				lastUploadDate.getFullYear() +
				"/" +
				(lastUploadDate.getMonth() + 1) +
				"/" +
				this.cartId +
				"/" +
				uuid() +
				".jpg";
			UploadService.uploadBlob(photo.croppedPhoto, nameOnBucket)
				.onProgress(e => {
					this.uploadProgress = e.loaded / e.total;
				})
				.then(response => {
					if (
						response.status === 201 &&
						photo.lastUploadDate.getTime() === lastUploadDate.getTime()
					) {
						console.debug("UploadService.uploadApproved", response.body);
						photo.S3Saved = true;
						photo.S3Response = response;
						this.persistPhotos();
						resolve(response);
					} else {
						console.debug(
							"UploadService.errorHttpResponseOnUpload",
							response.status
						);
						//console.debug("UploadService.photo.lastUploadDate.getTime()", photo.lastUploadDate.getTime());
						//console.debug("UploadService.lastUploadDate.getTime()", lastUploadDate.getTime());
						photo.S3Response = response;
						reject(response);
					}
				})
				.catch(err => {
					console.debug(err);
					reject(err);
				});
		});
	}

	reset(persistCustomerDataOnStorage) {
		if (persistCustomerDataOnStorage) {
			ConfigService.persistCustomerDataOnStorage(this);
		}

		this.dateReference = new Date();
		this.nextIndex = 0;
		this.uploadProgress = 1;
		this.needFreightCalc = true;
		this.uploadHasFinished = false;
		this.uploadingBatch = false;

		this.photos = [];
		this.address = null;
		this.personalData = null;
		this.paymentData = null;

		this.freightOption = null;
		this.freightOptions = null;
		this.coupon = null;
		this.couponResponse = null;
		this.gift = false;
		this.giftMessage = "";

		this.installments = 1;

		this.cartId = uuid();

		this.paymentApiResult = null;

		ConfigService.getCustomerDataFromStorage().then(values => {
			if (values) {
				this.personalData = values.personalData;
				this.address = values.address;
			}
		});

		this.fillTestInfos();
	}

	fillTestInfos() {
		//if (process.env.NODE_ENV !== 'production') {
		//    this.address = {"address": "Rua Santa Alexandrina", "city": "Rio de Janeiro", "complement": "Apto 110", "contactName": "Daniel Zupo ", "number": "123", "province": "Rio Comprido", "state": "RJ", "zipCode": "20261232"};
		//    this.freightOption = {"carrier": "CORREIOS", "code": "SEDEX", "errorCode": "0", "freightDefault": false, "name": "Sedex", "time": 14, "type": "CALCULATED", "valid": true, "value": 24.21};
		//    this.needFreightCalc = false;
		//    this.personalData = {"cpf": "122.847.957-73", "email": "zupo.daniel@gmail.com", "emailConf": "zupo.daniel@gmail.com", "fullName": "Daniel Zupo"};
		//}
	}

	generateServerDomain() {
		var doc = {
			cartId: this.cartId,
			installments: this.installments || 1
		};

		doc.customer = {
			email: this.personalData.email,
			telephones: {},
			type: {
				pf: {
					cpf: this.personalData.cpf.replace(/[^0-9]/g, ""),
					fullName: this.personalData.fullName
				}
			}
		};

		doc.address = {
			address: this.address.address,
			number: this.address.number,
			province: this.address.province,
			city: this.address.city,
			state: this.address.state,
			zipCode: this.address.zipCode,
			complement: this.address.complement,
			reference: this.address.reference,
			contactName: this.address.contactName
				? this.address.contactName
				: this.personalData.fullName
		};

		doc.items = [
			{
				quantity: this.getItensQuantity(),
				code: this.configs.selectedOption.code,
				photos: []
			}
		];

		doc.gift = this.gift;
		doc.giftMessage = this.giftMessage;

		for (let i = 0; i < this.photos.length; i++) {
			for (let j = 0; j < this.photos[i].quantity; j++) {
				doc.items[0].photos.push({
					file: this.photos[i].S3Response.body.PostResponse.Key,
					lowResolution: this.photos[i].lowResolution,
					quantity: this.photos[i].quantity
				});
			}
		}

		if (!this.isFullDiscount()) {
			doc.paymentForm = {
				installment: this.installments || 1,
				type: "CREDIT",
				cardNumber: this.paymentData.number.replace(/[^0-9]/g, ""),
				cardExpirationMonth: parseInt(
					this.paymentData.expirationMonth.replace(/\/.*/g, "")
				),
				cardExpirationYear: parseInt(
					this.paymentData.expirationYear.replace(/.*\//g, "")
				),
				cardSecurityCode: this.paymentData.CVV,
				cardHolderName: this.paymentData.name
			};
		}

		doc.carrier = this.freightOption.carrier;
		doc.freightCode = this.freightOption.code;

		if (this.couponResponse) {
			doc.coupons = [this.couponResponse.code];
		}

		doc.couponRaw = this.coupon;

		doc.clientVersion = navigator.appVersion;
		doc.platformVersion = navigator.userAgent;
		doc.platform = navigator.platform;

		return doc;
	}

	recoverPendingUploadPhotos() {
		console.info("init recoverPendingUploadPhotos");
		if (this.hasPhotosPendingUpload() && !this.uploadingBatch) {
			console.info("hasPendingPhotos");
			return this.startUpload();
		} else {
			console.info("allPhotosSaved");
			return Promise.resolve();
		}
	}

	persistProduct() {
		return new Promise((resolve, reject) => {
			Storage.saveInDB(Storage.KEYS.CONFIG, this.configs)
				.then(() => {
					console.debug("IndexedDB save success");
					resolve();
				})
				.catch(() => {
					console.debug("IndexedDB Error");
					reject();
				});
		});
	}

	persistPhotos() {
		return new Promise((resolve, reject) => {
			Storage.saveInDB(Storage.KEYS.PHOTOS, this.photos)
				.then(() => {
					console.debug("IndexedDB save success");
					resolve();
				})
				.catch(() => {
					console.debug("IndexedDB Error");
					reject();
				});
		});
	}
}

export default new Order();

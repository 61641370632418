import { Component } from 'react';
import {CPF} from 'cpf_cnpj';

export default class FormValidator extends Component {

    hasErrors(updateData) {
        var hasError = false;
        Object.keys(updateData).forEach((key, index) => {
            if (key.indexOf("Error") !== -1 && updateData[key] === true) {
                hasError = true
            }
        });
        updateData.blockScreen = hasError;
        updateData.validationStarted = true;
        return hasError;
    }

    validateCpf(cpf) {
        if (cpf) {
            if (cpf.length !== 14 || !CPF.isValid(cpf)) {
                return {
                    valid: false,
                    code: 'validation.incorrect.format',
                    message: 'Formato inválido',
                    validator: 'zipcode'
                }
            } else {
                return {valid: true}
            }
        } else {
            return {
                valid: false,
                code: 'validation.required',
                message: 'Campo necessário',
                validator: 'cpf'
            }
        }

    }

    validateZipCode(zipCode) {
        if (zipCode) {
            if (zipCode.length !== 9 || !/\d{5}-\d{3}/.test(zipCode)) {
                return {
                    valid: false,
                    code: 'validation.incorrect.format',
                    message: 'Formato inválido',
                    validator: 'zipcode'
                }
            } else {
                return {valid: true}
            }
        } else {
            return {
                valid: false,
                code: 'validation.required',
                message: 'Campo necessário',
                validator: 'zipcode'
            }
        }
    }

    validateRequired(val) {
        if (typeof val !== 'undefined' && val && val.trim().length > 0) {
            return {valid: true}
        } else {
            return {
                valid: false,
                code: 'validation.not.exists',
                message: 'Campo necessário',
                validator: 'required'
            }
        }
    }

    validateFullName(name) {
        if (name) {
            if (!name.match(/[^ ]+ [^ ]+/g)) {
                return {
                    valid: false,
                    code: 'validation.incorrect.format',
                    message: 'Use o nome completo',
                    validator: 'fullName'
                }
            } else {
                return {valid: true}
            }
        } else {
            return {
                valid: false,
                code: 'validation.required',
                message: 'Campo necessário',
                validator: 'fullName'
            }
        }
    }

    validateEmail(email) {
        if (email) {
            if (!email.match(/^[_A-Za-z0-9-+&.]+@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/g)) {
                return {
                    valid: false,
                    code: 'validation.incorrect.format',
                    message: 'Formato incorreto',
                    validator: 'email'
                }
            } else {
                return {valid: true}
            }
        } else {
            return {
                valid: false,
                code: 'validation.required',
                message: 'Campo necessário',
                validator: 'email'
            }
        }
    }

}
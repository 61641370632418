import React, { Component } from 'react';
import MasterPage from "../components/layout/MasterPage";
import InputField from '../components/InputField';
import { Link } from "react-router-dom";

class RegisterPersonalData extends Component {
    render() {
        return (
            <MasterPage>
                
                <h1 className="page-title">Seus dados pessoais</h1>
                <div className="login-box">
                    <div className="login-content">
                            <label className="form-label" htmlFor="option" >Selecione uma opção</label>
                                <select className="form-input" id="option">
                                    <option value="null">Como gostaria de ser chamado?</option>
                                    <option value="null">Sr. </option>
                                    <option value="null">Sra. </option>
                                </select>
                        <InputField label="Nome" type="text" placeholder="João da Silva" className="form-input login" />
                        <InputField label="CPF" mask="999.999.999-99" maskChar="" type="text" placeholder="123.456.789.00" className="form-input login" />
                        <InputField label="Nascimento" mask="99/99/9999" maskChar="" type="text" placeholder="/  / " className="form-input login" />
                        <InputField label="Senha" type="password" placeholder="*************" className="form-input login" />
                        <InputField label="Confirme senha" type="password" placeholder="*************" className="form-input login" />
                        <InputField label="Telefone" mask="(99) 99999-9999" maskChar=""type="text" placeholder="(21) 91234-1234" className="form-input login" />

                        <Link to="/register-complete" className="button">Prosseguir</Link>
                        </div>
                </div>
            </MasterPage>
            
        );
    }
}

export default RegisterPersonalData;
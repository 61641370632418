import cfg from '../../easyfoto/Config';

export function initKonduto() {

    window.__kdt = [];
    window.__kdt.push({"public_key": cfg.KONDUTO_KEY}); // A chave pública identifica a sua loja
    window.__kdt.push({"post_on_load": false});


    (function () {
        var kdt = document.createElement('script');
        kdt.id = 'kdtjs';
        kdt.type = 'text/javascript';
        kdt.async = true;
        kdt.src = 'https://i.k-analytix.com/k.js';
        var s = document.getElementsByTagName('body')[0];
        s.parentNode.insertBefore(kdt, s);
    })();


    window.kondutoSendEvent = function (event_type, event_name) {
        console.debug('Sending Event ("' + event_type + '", "' + event_name + '")');
        var period = 300;
        var limit = 20 * 1e3;
        var nTry = 0;
        var intervalID = setInterval(function () {
                var clear = limit / period <= ++nTry;
                if ((typeof(window.Konduto) !== "undefined") &&
                    (typeof(window.Konduto.sendEvent) !== "undefined")) {
                    window.Konduto.sendEvent(event_type, event_name); // Disparo programático de evento
                    clear = true;
                }
                if (clear) {
                    clearInterval(intervalID);
                }
            },
            period);
    };

    window.kondutoIdentify = function (customerID) {
        console.debug('Identifying', customerID);
        var period = 300;
        var limit = 20 * 1e3;
        var nTry = 0;
        var intervalID = setInterval(function () { // loop para retentar o envio
            var clear = limit / period <= ++nTry;
            if ((typeof(window.Konduto) !== "undefined") &&
                (typeof(window.Konduto.setCustomerID) !== "undefined")) {
                window.Konduto.setCustomerID(customerID); // envia o ID para a Konduto
                clear = true;
            }
            if (clear) {
                clearInterval(intervalID);
            }
        }, period);
    };

    window.getVisitorID = function () {
        if ((typeof(window.Konduto) !== "undefined") && (typeof(window.Konduto.getVisitorID) !== "undefined")) {
            return window.Konduto.getVisitorID();
        }else{
            return '';
        }
    };
}



const parser = require('fast-xml-parser');

export default class Request {

    constructor(url, method) {
        this.xhr = new XMLHttpRequest();
        this.formData = new FormData();
        this.xhr.open(method, url);
        this.promise = new Promise((resolve, reject) => {
            this.xhr.onload = Request.UTILS.buildResponseHandler(this.xhr, resolve, reject);
            this.xhr.onerror = Request.UTILS.buildResponseHandler(this.xhr, resolve, reject);
        });
        return this;
    }

    static UTILS = {
        isBlank: (string) => {
            return null == string || !/\S/.test(string);
        },
        notBlank: (string) => {
            return !Request.UTILS.isBlank(string);
        },
        parseHeaders: xhr => {
            return (xhr.getAllResponseHeaders() || '').split(/\r?\n/).filter(Request.UTILS.notBlank).reduce((headers, headerString) => {
                var header = headerString.split(":")[0];
                headers[header] = xhr.getResponseHeader(header);
                return headers;
            }, {});
        },
        buildResponseObject: xhr => {
            var headers = {};
            try {
                headers = Request.UTILS.parseHeaders(xhr);
            } catch (e) {
                console.debug(e);
            }
            return {
                status: xhr.status,
                body: parser.parse(xhr.responseText),
                headers: headers
            };
        },

        buildResponseHandler: (xhr, resolve, reject) => {
            return () => {
                var fn = xhr.status === 0 ? reject : resolve;
                fn(Request.UTILS.buildResponseObject(xhr));
            };
        }
    }

    setFormDataItem(key, value) {
        this.formData.set(key, value);
        return this;
    }

    setFile(blob, fileName) {
        this.formData.set('file', blob, fileName);
        return this;
    }

    setHeader(header, value) {
        this.xhr.setRequestHeader(header, value)
        return this;
    }

    send() {
        this.xhr.send(this.formData);
        return this;
    }

    abort() {
        this.xhr.abort();
        return this;
    }

    onProgress(fn) {
        if (this.xhr.upload) {
            this.xhr.upload.onprogress = e => {
                e.percent = e.loaded / e.total;
                fn(e);
            };
        }
        return this;
    }

    then(fn) {
        if (this.promise) {
            this.promise.then((e) => {
                fn(e);
            })
        }
        return this;
    }

    catch(fn) {
        if (this.promise) {
            this.promise.catch((e) => {
                fn(e);
            })
        }
        return this;
    }

    setPolicy(policy) {
        Object.keys(policy)
            .forEach(k => {
                this.setFormDataItem(k, policy[k]);
            });
        return this;
    }
}
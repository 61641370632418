
import Cfg from '../Config';
import Axios from 'axios';

class HttpService{

    constructor(){

        var instance = Axios.create();
        instance.defaults.headers.common['Authorization'] = Cfg.API.basicAuth
        instance.defaults.timeout = 15000;
        this.axios = instance;

    }

}

export default new HttpService();
export default class OrderConfigs {

    constructor() {
        //default values, will be overwritten by server values
        this.maxQuantity = 20;
        this.startQuantity = 5;
        this.startPrice = 150;
        this.price = 27;
        this.code = "SDJM0000001";
        this.antiFraudKey = "PB177BC1FEC";
    }

}
import Cfg from '../Config';
import MainService from './MainService';
import HttpService from './HttpService';
import order from '../Order';

export default class OrderService extends MainService {

    static closeOrder(callback) {

        var doc = order.generateServerDomain();

        console.debug("OrderService: Closing Order", doc);

        HttpService.axios.put(Cfg.API.url + 'bff/checkout', doc)
            .then(res => {
                callback(res);
            })
            .catch(err => {
                console.debug('error', err);
                console.debug('error', err.response);
                callback(null);
            });

    }

    static validateCoupon(couponCode) {
        return HttpService.axios.get(Cfg.API.url + 'bff/coupon/' + couponCode.trim())
    }

}
import Cookies from 'js-cookie';
import localforage from "localforage";

const db = localforage.createInstance({
    name: "easyfoto4u"
});

db.setDriver(localforage.INDEXEDDB);

class Storage {
    static KEYS = {
        USERTOKEN: 'userToken',
        USERDATA: 'userData',
        CART: 'cart',
        PHOTOS: 'photos',
        LOCALCART: 'localCart',
        CARTID: 'cartID',
        DELIVERY: 'delivery',
        COUPON: 'coupon',
        CONFIG: "configs",
        CUSTOMER_DATA: "order_data",
        COUPON_OBJECT: 'coupon_obj',
        UTM: {
            CODE: 'utm_code',
            CAMPAIGN: 'utm_campaign',
        }
    };
    static campaignCodes = ["easy_partner", "utm_source", "utm_medium", "utm_term", "utm_content", "utm_campaign"];

    static getDomain() {
        if (/.*?\..*?\.com\.br/.test(window.location.host) || /.*?\..*?\.com/.test(window.location.host)) {
            var domain = window.location.host.substr(window.location.host.indexOf('.') + 1);
            return domain;
        } else {
            return window.location.host;
        }
    }

    static set(index, value) {
        localStorage.setItem(index, window.btoa(value.toString()));
    }

    static setJSON(index, value) {
        Storage.set(index, JSON.stringify(value));
    }

    static saveInDB(index, value) {
        return db.setItem(index, value);
    }

    static get(index) {
        var result = null;
        try {
            result = localStorage.getItem(index);
            if (result) {
                result = window.atob(result);
            }
        } catch (e) {
            console.debug(e);
            result = null;
        }
        return result;
    }

    static getJSON(index) {
        var result = null;
        try {
            result = JSON.parse(Storage.get(index));
        } catch (e) {
            console.debug(e);
        }
        return result;
    }

    static loadFromDB(index) {
        return db.getItem(index);
    }

    static remove(index) {
        localStorage.removeItem(index);
    }

    static setCookie(index, value, options) {
        if (typeof options === 'undefined') {
            options = {expires: 7, path: "/", domain: Storage.getDomain()};
        }
        Cookies.set(index, window.btoa(value), options);
    }

    static setCookieJSON(index, value, options) {
        Storage.setCookie(index, JSON.stringify(value))
    }

    static getCookie(index) {
        var cookieVal = Cookies.getItem(index), decodedCookieVal;
        if (cookieVal) {
            try {
                decodedCookieVal = window.atob(cookieVal);
                return decodedCookieVal;
            } catch (e) {
                console.debug(e);
                return cookieVal;
            }
        }

        return null;

    }

    static getCookieJSON(index) {

        var cookieVal = Storage.getCookie(index), parsedCookieVal;
        if (cookieVal) {
            try {
                parsedCookieVal = JSON.parse(cookieVal);
                return parsedCookieVal;
            } catch (e) {
                console.debug(e);
                return cookieVal;
            }
        }

        return null;

    }

    static removeCookie(index) {
        Cookies.setItem(index, "", {expires: 7, path: "/", domain: Storage.getDomain()});
        Cookies.removeItem(index, {expires: 7, path: "/", domain: Storage.getDomain()});
    };

    static getAndRemove(index) {
        var value = Storage.get(index);
        Storage.remove(index);
        return value;
    };

    static getCookieAndRemove(index) {
        var value = Storage.getCookie(index);
        localStorage.removeCookie(index);
        return value;
    };

}

export default Storage;
import React, { Component, Fragment } from 'react';
import MasterPage from "../components/layout/MasterPage";
import InputField from '../components/InputField';
import {DefaultButton} from "../components/DefaultButton";

class LostPass extends Component {
    constructor(props){
        super(props)

        this.state = {
            sendState: false,
        }
    }

    sendPassState(){
        this.setState({sendState: !this.state.sendState});
    }

    render() {
        return (
            <MasterPage>
                
                <h1 className="page-title">Recuperação de senha</h1>
                <div className="login-box">
                    <div className="login-content">
                    {
                        !this.state.sendState?
                    <Fragment>
                    <InputField label="Digite seu e-mail" type="text" placeholder="joao@email.com" className="form-input login" />
                    <DefaultButton onClick={this.sendPassState.bind(this)} text="Enviar" />
                    </Fragment>
                    :
                    <Fragment>
                        <h3 className="page-title">Tudo certo</h3>  
                        <span className="icon-success"></span>
                        <p className="lost-pass-text">Um link para recuper sua senha de acesso foi enviado para seu e-mail.</p>
                    </Fragment>
                     }

                        </div>
                </div>
            </MasterPage>
            
        );
    }
}

export default LostPass;
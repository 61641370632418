import "cropperjs/dist/cropper.css";
import React, { Component } from "react";
import MasterPage from "../components/layout/MasterPage";
import Order from "../easyfoto/Order";
import ConfigService from "../easyfoto/services/ConfigService";
import { toStringCurrency } from "../helpers/string";

export default class SelectProductScene extends Component {
	constructor(props) {
		super(props);

		this.state = {
			productOptions: [],
			selectedProductOption: null,
			loading: true
		};

		ConfigService.refreshConfigs(Order).then(() => {
			if (Order.configs) {
				this.state.productOptions = Order.configs.options;
				this.state.selectedProductOption = Order.configs.selectedOption;
			}
			this.setState({
				loading: false
			});
		});
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	onChangeProduct = async option => {
		Order.reset(true);

		Order.configs.selectedOption = option;

		await Order.persistProduct();

		this.setState({
			selectedProductOption: option
		});

		this.props.history.push("/start");
	};

	selectedProduct = opt => {
		return this.state.selectedProductOption.code === opt.code
			? "card selected"
			: "card";
	};

	render() {
		return (
			<MasterPage loading={this.state.loading}>
				<div
					style={{
						flex: 1,
						display: "flex",
						justifyContent: "center"
					}}
				>
					<h1 className="page-title page-title2">Escolha o produto</h1>
					<div className="cards-list">
						{this.state.productOptions
							? this.state.productOptions.map((opt, key) => {
									return (
										<div
											key={key}
											className={this.selectedProduct(opt)}
											onClick={() => {
												this.onChangeProduct(opt);
											}}
										>
											<div className="card_image">
												<img alt={opt.name} src={opt.photo || "/icon.png"} />
											</div>
											<div className="card_title">
												<p>A partir de {toStringCurrency(opt.startPrice)}</p>
											</div>
										</div>
									);
							  })
							: null}
					</div>
				</div>
			</MasterPage>
		);
	}
}

const config = {
  AWSOPTIONS: {
    keyPrefix: "upload/",
    bucket: "easyfoto4u",
    region: "sa-east-1",
    accessKey: `${process.env.REACT_APP_ACCESS_KEY}`,
    secretKey: `${process.env.REACT_APP_SECRET_KEY}`,
    successActionStatus: 201,
    acl: ""
  },

  API: {
    url: `${process.env.REACT_APP_API_URL}`,
    basicAuth: "Basic YmctaW5mby11c2VyOjBRZzVKI3J0NExQYTNNbg=="
  },

  IMAGEOPTIONS: {
    width: 1200,
    height: 1200,
    quality: 0.8
  },

  MIN_UNITS: 5,
  MAX_ITEM_ON_CART: 20,
  MAX_COPIES_PER_PHOTO: 50,
  KONDUTO_KEY: "P4C101CA7C6",

  colorfulHeader: true
};

export default config;

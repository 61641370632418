import React, { Component } from "react";
import MasterPage from "../components/layout/MasterPage";
import { PhotoFrame, PhotoAddButton } from "../components/PhotoFrame";
import { PhotoList } from "../components/Panels";
import { DefaultButton, LinkButton } from "../components/DefaultButton";
import Breadcrumb from "../components/Breadcrumb";
import ResizeAndCropService from "../easyfoto/services/ResizeAndCropService";
import Cropper from "react-cropper";
import Cfg from "../easyfoto/Config";
import ConfigService from "../easyfoto/services/ConfigService";
import Storage from "../easyfoto/Storage";
import "cropperjs/dist/cropper.css";

import Photo from "../easyfoto/Photo";
import Order from "../easyfoto/Order";

export default class PhotoScene extends Component {
	constructor(props) {
		super(props);

		this.cropperRef = React.createRef();

		this.state = {
			imageList: [],
			editorOpenned: false,
			photoToEdit: null,
			photoPermission: "undetermined",
			showOnlyHighRes: Order.showOnlyHighRes,
			selectedPhotosSize: 0,
			isCropping: 0,
			loading: 1,
			uploading: false
		};

		ConfigService.loadCachedConfigsValue(Order).then(() => {
			Order.onUploadHasStarted(() => {
				console.debug("PhotoScene: Starting Upload");
				this.setState({ uploading: true });
				Order.uploadHasFinished = false;
			}).onUploadHasFinished(() => {
				console.debug("PhotoScene: Upload has Ended");
				Order.uploadHasFinished = true;
				this.setState({ uploading: Order.hasPhotosPendingUpload() });
			});

			Order.loadPhotosFromDB()
				.then(photos => {
					if (photos && photos.length > 0) {
						window.kondutoSendEvent("page", "home");
					} else {
						window.kondutoSendEvent("page", "deliveryDataForm");
					}

					Order.recoverPendingUploadPhotos();
					this.setState({
						imageList: photos,
						loading: 0
					});
				})
				.catch(err => {
					console.debug(err);
				});
		});
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this._startDropFileSupport();
		document.addEventListener(
			"keydown",
			e => {
				this._escFunction(e);
			},
			false
		);

		window.onpopstate = e => {
			this.setState({
				editorOpenned: false
			});
			return false;
		};
	}

	componentWillUnmount() {
		document.removeEventListener(
			"keydown",
			e => {
				this._escFunction(e);
			},
			false
		);
	}

	_escFunction(event) {
		if (this.state.editorOpenned) {
			if (event.keyCode === 27) {
				this.setState({
					editorOpenned: false
				});
			}
		}
	}

	_startDropFileSupport() {
		let dropArea = document.querySelector(".app-container");

		//desabilitando propagação dos eventos
		["dragenter", "dragover", "dragleave", "drop"].forEach(eventName => {
			dropArea.addEventListener(
				eventName,
				e => {
					e.preventDefault();
					e.stopPropagation();
				},
				false
			);
		});

		["dragenter", "dragover"].forEach(eventName => {
			dropArea.addEventListener(
				eventName,
				() => {
					dropArea.classList.add("droppingContent");
				},
				false
			);
		});

		["dragleave", "drop"].forEach(eventName => {
			dropArea.addEventListener(
				eventName,
				() => {
					dropArea.classList.remove("droppingContent");
				},
				false
			);
		});

		dropArea.addEventListener(
			"drop",
			e => {
				let dt = e.dataTransfer;
				let files = dt.files;
				this._updateList(
					[...files].filter(
						s => s.type.includes("image/jpeg") || s.type.includes("image/png")
					)
				);
			},
			false
		);
	}

	_updateList(fileList) {
		console.debug(`PhotoPickerPage: Enviando ${fileList.length} fotos`);

		Order.uploadHasFinished = false;
		Order.needFreightCalc = true;

		this.setState({ loading: 1, uploading: true }, () => {
			let promises = [];

			fileList.forEach(file => {
				promises.push(this._addPhoto(file));
			});

			Promise.all(promises)
				.then(() => {
					console.debug("todas as imagens processadas com sucesso");
				})
				.catch(e => {
					console.debug("erro ao processar alguma imagem", e);
				})
				.finally(() => {
					Storage.saveInDB(Storage.KEYS.PHOTOS, Order.photos)
						.then(() => {
							console.debug("imagens persistidas com sucesso");
							Order.startUpload();
						})
						.catch(e => {
							console.debug("IndexedDB Error", e);
						})
						.finally(() => {
							this.setState({ loading: 0 });
						});
				});
		});
	}

	_addPhoto(file) {
		return new Promise((result, reject) => {
			try {
				let photoFound = Order.photos.some(item => {
					return item.originalFileName === file.name;
				});
				if (photoFound) {
					result();
				} else {
					console.debug("Incluindo foto");
					ResizeAndCropService.getCentralizedCroppedImage(file).then(
						blobCentralizedDescriptor => {
							Order.photos.push(
								new Photo(
									Order.getIndex(),
									file.name,
									file,
									blobCentralizedDescriptor.blobRef,
									blobCentralizedDescriptor.originalWidth,
									blobCentralizedDescriptor.originalHeight
								)
							);

							this.setState(
								{
									selectedPhotosSize: Order.photos.length,
									imageList: Order.photos
								},
								() => {
									result();
								}
							);
						}
					);
				}
			} catch (e) {
				reject(e);
			}
		});
	}

	_removePhoto(photo) {
		Order.photos = Order.photos.filter(item => {
			return item.originalFileName !== photo.originalFileName;
		});

		this.setState({
			selectedPhotosSize: Order.photos.length,
			imageList: Order.photos,
			loading: 0
		});

		Order.persistPhotos();
	}

	_editPhoto(photo) {
		ResizeAndCropService.blobToBase64String(photo.originalPhoto).then(
			base64Image => {
				this.setState({
					editorOpenned: true,
					photoToEdit: photo,
					photoToCrop: base64Image
				});
			}
		);
	}

	_changeCopies(photo, qtd) {
		//if((photo.quantity > 1 && qtd === -1 ) || (photo.quantity < Order.MAX_COPIES_PER_PHOTO && qtd === 1)){
		if ((photo.quantity > 1 && qtd === -1) || qtd === 1) {
			photo.quantity = photo.quantity + qtd;
			this.setState({ imageList: this.state.imageList });
			Order.persistPhotos();
		}
	}

	_closeEditPhoto() {
		this.setState({
			editorOpenned: false
		});
	}

	_onPhotoReady() {
		console.debug("ready");
		this.setState({ imageList: Order.photos });
	}

	_fileChangedHandler(event) {
		console.debug("Enviando fotos");
		if (event.target.files[0]) {
			this._updateList([...event.target.files]);
		}
	}

	_crop() {
		this.setState(
			{
				isCropping: 1
			},
			() => {
				let photoToEdit = this.state.photoToEdit;

				console.debug(
					"====>>>",
					this.cropperRef.current.getCroppedCanvas({
						fillColor: "#000",
						width: Cfg.IMAGEOPTIONS.width,
						height: Cfg.IMAGEOPTIONS.height,
						imageSmoothingQuality: "high"
					})
				);

				ResizeAndCropService.canvasToBlob(
					this.cropperRef.current.getCroppedCanvas({
						fillColor: "#000",
						width: Cfg.IMAGEOPTIONS.width,
						height: Cfg.IMAGEOPTIONS.height,
						imageSmoothingQuality: "high"
					})
				).then(croppedPhoto => {
					photoToEdit.croppedPhoto = croppedPhoto;

					this.setState({
						imageList: this.state.imageList.map(photo => {
							if (photo.originalFileName === photoToEdit.originalFileName) {
								photo.croppedPhoto = this.state.photoToEdit.croppedPhoto;
								Order.persistPhotos();
								Order.uploadCroppedPhoto(photo);
							}
							return photo;
						}),
						editorOpenned: false,
						isCropping: 0
					});
				});
			}
		);
	}

	_removeAllPhotos() {
		Order.photos = [];
		this.setState({
			selectedPhotosSize: Order.photos.length,
			imageList: Order.photos,
			loading: 0
		});
		Order.persistPhotos();
	}

	render() {
		let selectedProduct = Order.getSelectedProduct();

		if (Order.photos.length === 0) {
			return (
				<MasterPage loading={this.state.loading}>
					<div className="intro">
						<img
							className="intro-image"
							src={require("../assets/images/insta.jpg")}
							alt="EasyFotoBrasil"
						/>
						<h3>Seja bem-vindo</h3>
						<p>
							Para começar você precisa ter suas fotos armazenadas em seu
							computador, celular ou tablet.
						</p>
						<p>
							Use o botão abaixo para importar suas fotos para esta página. Após
							isso você deverá editar as fotos individualmente.
						</p>
						<PhotoAddButton onChange={this._fileChangedHandler.bind(this)} />
					</div>
				</MasterPage>
			);
		} else if (this.state.editorOpenned) {
			return (
				<MasterPage loading={this.state.loading}>
					<React.Fragment>
						<h1 className="page-title">Ajuste a foto</h1>
						<div className="cropper-holder">
							<div className="cropper-container">
								<Cropper
									ref={this.cropperRef}
									src={this.state.photoToCrop}
									style={{ height: 500, width: 500 }}
									// Cropper.js options
									viewMode={1}
									dragMode={"none"}
									rotatable={false}
									zoomable={false}
									zoomOnWheel={false}
									setCropBoxData={{
										left: 10,
										top: 10,
										width: 200,
										height: 200
									}}
									aspectRatio={1 / 1}
									guides={true}
								/>
							</div>
						</div>
						<div className="button-next">
							<DefaultButton
								onClick={e => {
									this._closeEditPhoto();
								}}
								text="Voltar"
							/>
							<DefaultButton
								onClick={e => {
									this._crop();
								}}
								text="Pronto"
								loading={this.state.isCropping}
							/>
						</div>
					</React.Fragment>
				</MasterPage>
			);
		} else {
			return (
				<MasterPage loading={this.state.loading}>
					<React.Fragment>
						<Breadcrumb step={"photo"} />
						<h1 className="page-title">Adicione e edite suas fotos</h1>
						<PhotoList>
							{this.state.imageList.map((item, key) => {
								return (
									<PhotoFrame
										key={"photo_" + item.originalFileName}
										photo={item.croppedPhoto}
										lowResolution={item.lowResolution}
										fileName={item.originalFileName}
										quantity={item.quantity}
										onEditClick={() => {
											this._editPhoto(item);
										}}
										onRemoveClick={() => {
											this._removePhoto(item);
										}}
										addCopyClick={() => {
											this._changeCopies(item, +1);
										}}
										removeCopyClick={() => {
											this._changeCopies(item, -1);
										}}
									/>
								);
							})}
							<PhotoAddButton onChange={this._fileChangedHandler.bind(this)} />
						</PhotoList>

						<div className="button-panel">
							{Order.getItensQuantity() < selectedProduct.startQuantity ? (
								<DefaultButton
									disabled={true}
									text={`Mínimo de ${selectedProduct.startQuantity} fotos`}
								/>
							) : (
								<LinkButton
									to="/delivery"
									className="button-next"
									text="Prosseguir"
								/>
							)}
							<button
								onClick={this._removeAllPhotos.bind(this)}
								className="button button-ghost"
							>
								Remover todas as fotos
							</button>
						</div>
					</React.Fragment>
				</MasterPage>
			);
		}
	}
}

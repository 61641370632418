export default class DateUtils {
    static YYYYMMDD = 'yyyymmdd';
    static ISO8601 = 'iso8601';
    static AMZ_ISO8601 = 'amz-iso8601';

    static dateToString(date, format) {

        switch (format) {
            case DateUtils.ISO8601:
                return date.toISOString();

            case DateUtils.YYYYMMDD:
                return DateUtils.dateToString(date, DateUtils.ISO8601).slice(0, 10).replace(/-/g, "");

            case DateUtils.AMZ_ISO8601:
                return DateUtils.dateToString(date, DateUtils.YYYYMMDD) + 'T000000Z';

            default:

        }
    }
}
import React, { Component } from "react";
import idGenerator from "react-id-generator";

const ENTER_KEY_CODE = 13;

class TextAreaField extends Component {
	constructor(props) {
		super(props);

		const notImplementedValidation = v => {
			console.debug("TextAreaField: Validation not implemented");
		};

		this.htmlId = "input_" + idGenerator();
		this.onChange = props.onChange;
		this.onSubmit = props.onSubmit;
		this.validate = props.validation || notImplementedValidation;
		this.state = {
			value: props.value || "",
			validationMessage: null
		};
	}

	_onChange(event) {
		if (this.onChange) {
			this.onChange(event.target.value);
		}

		let newState = {
			value: event.target.value
		};

		const validationResult = this.validate(event.target.value);
		if (validationResult) {
			if (!validationResult.valid) {
				newState.validationMessage = validationResult.message;
			} else {
				newState.validationMessage = null;
			}
		}

		this.setState(newState);
	}

	_onOptionChange(event) {
		if (this.onChange) {
			this.onChange(event.target.checked);
		}
		this.setState({
			value: event.target.checked
		});
	}

	render() {
		let classes = "form-input";

		if (this.state.validationMessage) {
			classes += " error";
		}

		return (
			<div className="form-input-wrapper">
				{this.props.label ? (
					<label htmlFor={this.htmlId} className="form-label">
						{this.props.label}
					</label>
				) : null}
				<textarea
					className={classes}
					id={this.htmlId}
					placeholder={this.props.placeholder}
					readOnly={this.props.readonly}
					disabled={this.props.disabled}
					maxLength={this.props.maxLength}
					minLength={this.props.minLength}
					value={this.state.value}
					onChange={this._onChange.bind(this)}
					mask={this.props.mask}
					maskChar={this.props.maskChar}
					autoComplete={this.props.autoComplete}
					rows={this.props.rows}
				/>
				{this.state.validationMessage ? (
					<span className="form-validation">
						{this.state.validationMessage}
					</span>
				) : null}
			</div>
		);
	}
}

export default TextAreaField;

import React, { Component, Fragment } from "react";
import InputField from "./InputField";
import InputMask from "react-input-mask";
import Alert from "./Alerts";

import Order from "../easyfoto/Order";
import StringUtils from "../easyfoto/StringUtils";

class DataCard extends Component {
	constructor(props) {
		super(props);

		const notImplementedValidation = v => {
			console.debug("InputField: Validation not implemented");
		};

		this.cardNumberValidate =
			props.cardNumberValidation || notImplementedValidation;
		this.cardHolderValidate =
			props.cardHolderValidation || notImplementedValidation;
		this.cardMonthValidate =
			props.cardMonthVcardYearVlidation || notImplementedValidation;
		this.cardYearValidate =
			props.cardYearValidation || notImplementedValidation;
		this.cardCVVValidate = props.cardCVVValidation || notImplementedValidation;

		this.state = {
			cardNumber: this.props.cardNumber || "",
			cardHolder: this.props.cardHolder || "",
			expirationMonth: this.props.expirationMonth || "",
			expirationYear: this.props.expirationYear || "",
			CVV: this.props.CVV || "",
			installments: this.props.installments
		};
	}

	_onCardNumberChange = value => {
		this.setState(
			{
				cardNumber: value
			},
			() => {
				if (this.props.onChange) {
					this.props.onChange({
						cardNumber: this.state.cardNumber,
						cardHolder: this.state.cardHolder,
						expirationMonth: this.state.expirationMonth,
						expirationYear: this.state.expirationYear,
						CVV: this.state.CVV,
						installments: this.state.installments
					});
				}
			}
		);
	};
	_onInstallmentsChange = event => {
		this.setState(
			{
				installments: event.target.value
			},
			() => {
				if (this.props.onChange) {
					this.props.onChange({
						cardNumber: this.state.cardNumber,
						cardHolder: this.state.cardHolder,
						expirationMonth: this.state.expirationMonth,
						expirationYear: this.state.expirationYear,
						CVV: this.state.CVV,
						installments: this.state.installments
					});
				}
			}
		);
	};
	_onCardHolderChange = value => {
		this.setState(
			{
				cardHolder: value
			},
			() => {
				if (this.props.onChange) {
					this.props.onChange({
						cardNumber: this.state.cardNumber,
						cardHolder: this.state.cardHolder,
						expirationMonth: this.state.expirationMonth,
						expirationYear: this.state.expirationYear,
						CVV: this.state.CVV,
						installments: this.state.installments
					});
				}
			}
		);
	};
	_onCardExpirationMonthChange = event => {
		this.setState(
			{
				expirationMonth: event.target.value
			},
			() => {
				if (this.props.onChange) {
					this.props.onChange({
						cardNumber: this.state.cardNumber,
						cardHolder: this.state.cardHolder,
						expirationMonth: this.state.expirationMonth,
						expirationYear: this.state.expirationYear,
						CVV: this.state.CVV,
						installments: this.state.installments
					});
				}
			}
		);
	};
	_onCardExpirationYearChange = event => {
		this.setState(
			{
				expirationYear: event.target.value
			},
			() => {
				if (this.props.onChange) {
					this.props.onChange({
						cardNumber: this.state.cardNumber,
						cardHolder: this.state.cardHolder,
						expirationMonth: this.state.expirationMonth,
						expirationYear: this.state.expirationYear,
						CVV: this.state.CVV,
						installments: this.state.installments
					});
				}
			}
		);
	};
	_onCardCVVChange = event => {
		this.setState(
			{
				CVV: event.target.value
			},
			() => {
				if (this.props.onChange) {
					this.props.onChange({
						cardNumber: this.state.cardNumber,
						cardHolder: this.state.cardHolder,
						expirationMonth: this.state.expirationMonth,
						expirationYear: this.state.expirationYear,
						CVV: this.state.CVV,
						installments: this.state.installments
					});
				}
			}
		);
	};

	render() {
		let mon = [];
		let i = 1;

		let installments = [];

		let total = Order.calcTotalPrice();

		let _installments = 1;
		let maxInstallment = Math.trunc(total / 50);

		if (maxInstallment > 3) maxInstallment = 3;
		else if (maxInstallment < 1) maxInstallment = 1;

		while (_installments <= maxInstallment) {
			installments.push(
				<option
					key={_installments}
					selected={i === this.state.installments}
					value={_installments}
				>
					{_installments}x {StringUtils.priceToText(total / _installments)}
				</option>
			);
			_installments++;
		}

		while (i <= 12) {
			mon.push(
				<option key={i} value={i}>
					{i}{" "}
				</option>
			);
			i++;
		}

		let year = [];
		let j = new Date().getFullYear();
		let limitYear = j + 10;

		while (j <= limitYear) {
			year.push(
				<option key={j} value={j}>
					{j}{" "}
				</option>
			);
			j++;
		}

		return (
			<Fragment>
				<InputField
					onChange={this._onCardNumberChange.bind(this)}
					validate={this.cardNumberValidate}
					mask="9999 9999 9999 9999"
					label="Número do Cartão"
					placeholder="4444 4444 4444 4444"
					className="form-input card-number"
					autoComplete="cc-number"
				/>
				<InputField
					onChange={this._onCardHolderChange.bind(this)}
					validate={this.cardHolderValidate}
					label="Nome do Titular"
					placeholder="Joao da Silva"
					className="form-input card-name"
					autoComplete="cc-name"
				/>

				<div className="box-data-card">
					<div className="data-card month">
						<label className="form-label">Validade</label>
						<select
							className="form-input"
							id="month"
							autoComplete="cc-exp-month"
							onChange={this._onCardExpirationMonthChange.bind(this)}
						>
							<option selected disabled value="null" />
							{mon}
						</select>
					</div>

					<div className="data-card year">
						<select
							className="form-input"
							autoComplete="cc-exp-year"
							onChange={this._onCardExpirationYearChange.bind(this)}
						>
							<option selected disabled value="null" />
							{year}
						</select>
					</div>

					<div className="data-card cvv">
						<label className="form-label" htmlFor="ccv">
							CVV
						</label>
						<InputMask
							onChange={this._onCardCVVChange.bind(this)}
							validate={this.cardCVVValidate}
							type="text"
							mask="999"
							placeholder="999"
							className="form-input"
							id="ccv"
							autoComplete="cc-csc"
						/>
					</div>
					<div className="data-card installment">
						<label className="form-label" htmlFor="installments">
							Parcela(s)
						</label>
						<select
							onChange={this._onInstallmentsChange.bind(this)}
							className="form-input"
						>
							{installments}
						</select>
					</div>
				</div>
				<Alert
					title="Ops..."
					description="O número do cartão parece inválido, revise e tente novamente."
				/>
			</Fragment>
		);
	}
}

export default DataCard;

import React, { Fragment } from 'react';
import MasterPage from "../components/layout/MasterPage";
import { DefaultPanel } from '../components/Panels';
import InputZipcode from "../components/InputZipcode";
import InputField from '../components/InputField';
import OptionSelector from "../components/OptionSelector";
import { LinkButton } from "../components/DefaultButton"
import Alert from "../components/Alerts"
import DeliveryService from "../easyfoto/services/DeliveryService"
import ConfigService from "../easyfoto/services/ConfigService";
import FormValidator from "../easyfoto/FormValidator"
import Order from '../easyfoto/Order';
import Breadcrumb from "../components/Breadcrumb";
import StringUtils from "../easyfoto/StringUtils";

export default class DeliveryScene extends FormValidator {

    constructor(props) {
        super(props);
        var preState = {
            blockScreen: true,
            addressFound: false,
            destinatary: false,
            freightOptions: [],
            productOptions: [],
            selectedProductOption: null,
            freightOption: null,
            loading: true,
            searchingZipCode: false,
            alertMessage: '',
            alertTitle: '',
            alertShow: false
        };

        this.state = preState;
        Order.freightOption = null;
        Order.needFreightCalc = true;
    }

    componentDidMount() {

        window.kondutoSendEvent("page", "deliveryDataForm");

        window.scrollTo(0, 0);

        var preState = {};


        ConfigService.getCustomerDataFromStorage()
            .then((customerData) => {
                Order.loadPhotosFromDB()
                    .then((photos) => {
                        if (Order.photos.length > 0) {

                            if (customerData) {
                                Order.personalData = customerData.personalData;
                                Order.address = customerData.address;
                            }

                            if (Order.configs) {
                                this.state.productOptions = Order.configs.options;
                                this.state.selectedProductOption = Order.configs.selectedOption;

                            }

                            if (Order.address) {
                                const addr = Order.address;
                                preState.city = addr.city;
                                preState.address = addr.address;
                                preState.province = addr.province;
                                preState.state = addr.state;
                                preState.zipCode = addr.zipCode;
                                preState.number = addr.number;
                                preState.complement = addr.complement;
                                preState.contactName = addr.contactName;
                                preState.addressFound = true;
                                preState.freightOptions = Order.freightOptions;
                                preState.searchingZipCode = false;
                            }
                            preState.loading = false;
                            this.setState(preState, () => {
                                if (Order.needFreightCalc && preState.zipCode) {
                                    this.findZipCode(preState.zipCode, true);
                                }
                            });
                        } else {
                            window.location.href = '/';
                        }
                    })
                    .catch(() => {
                        window.location.href = '/';
                    })

            })
            .catch((err) => {
                console.debug(err);
            })


    }

    forwardAvailable() {
        const freightSelected = this.state.freightOption != null;
        const contactNameTyped = this.validateFullName(this.state.contactName).valid;
        const numberTyped = this.validateRequired(this.state.number).valid;

        let addressTyped = true;
        let addressProvince = true;

        if (!this.state.address) {
            addressTyped = this.validateRequired(this.state.addressForm).valid;
            addressProvince = this.validateRequired(this.state.province).valid;
        }

        return (freightSelected && contactNameTyped && numberTyped && addressTyped && addressProvince);
    }

    findZipCode(zipCode, onlyUpdateFreighValue = false) {

        console.debug('CartDelivery: Buscando CEP', zipCode);

        this.setState({ searchingZipCode: true });

        Order.address = Order.address || {};

        Order.address.zipCode = zipCode;

        ConfigService.persistCustomerDataOnStorage(Order);

        DeliveryService.findZipcodeInfos(zipCode, Order.configs.selectedOption.code, Order.getItensQuantity())
            .then(zipCodeApiResult => {

                if (zipCodeApiResult) {

                    if (zipCodeApiResult.status !== 200 || (zipCodeApiResult.data && typeof zipCodeApiResult.data.zipCodeAddress === 'undefined')) {

                        this.zipcodeNotFound();

                    } else {

                        const dataZipCode = zipCodeApiResult.data.zipCodeAddress;
                        const freightOptions = zipCodeApiResult.data.freightOptions;

                        Order.needFreightCalc = false;
                        Order.freightOptions = freightOptions;

                        let preSelectedOption = freightOptions[0].options[0];

                        if (Order.freightOption) {

                            const sameCodeFilter = (item) => {
                                return item.code === Order.freightOption.code
                            }

                            if (freightOptions.some(sameCodeFilter)) {
                                console.debug('CartDelivery: Incluindo frete', freightOptions.map(sameCodeFilter)[0].code);
                                this.setFreight(freightOptions.map(sameCodeFilter)[0]);
                            }

                        } else {

                            console.debug('CartDelivery: Pedido ainda não possui Frete, incluindo', preSelectedOption.code);
                            this.setFreight(preSelectedOption);

                        }

                        const preState = {
                            freightOptions: freightOptions,
                            freightOption: Order.freightOption,
                            searchingZipCode: false,
                            addressFound: true
                        }

                        if (onlyUpdateFreighValue) {
                            this.setState(preState);
                        } else {
                            Order.address = {
                                city: dataZipCode.city,
                                address: dataZipCode.address,
                                province: dataZipCode.neighborhood,
                                state: dataZipCode.state,
                                zipCode: dataZipCode.zipCode,
                                number: this.state.number,
                                complement: this.state.complement,
                                contactName: this.state.contactName
                            };
                            this.setState({
                                city: dataZipCode.city,
                                address: dataZipCode.address,
                                province: dataZipCode.neighborhood,
                                state: dataZipCode.state,
                                zipCode: dataZipCode.zipCode,
                                ...preState
                            })

                        }
                        ConfigService.persistCustomerDataOnStorage(Order);
                    }
                } else {
                    this.zipcodeNotFound();
                }
            })
            .catch(err => {
                console.debug('CartDelivery: error');
                console.debug(err.response);
                try {
                    if (err.response.status === 404) {
                        this.zipcodeNotFound();
                        return;
                    }
                } catch (ex) {
                    this.setState({
                        searchingZipCode: false,
                        alertShow: true,
                        alertTitle: 'Ops',
                        alertMessage: 'Não encontramos o seu cep'
                    });
                }
            });
    }

    zipcodeNotFound() {
        console.debug('CartDelivery: Cep não retornou resultado');
        this.setState({
            searchingZipCode: false,
            alertShow: true,
            alertTitle: 'Endereço não encontrado',
            alertMessage: 'O CEP está digitado corretamente?'
        });
    }

    onChangeFreight = (option) => {
        Order.freightOption = option;
        this.setState({
            freightOption: option
        });
    }


    onAlertClose = () => {
        this.setState({ alertShow: false });
    }

    setContact = (contactName) => {
        this.setState({
            contactName: contactName
        }, () => {
            Order.address.contactName = contactName;
            ConfigService.persistCustomerDataOnStorage(Order);
        })
    }

    setNumber = (addressNumber) => {
        this.setState({
            number: addressNumber
        }, () => {
            Order.address.number = addressNumber;
            ConfigService.persistCustomerDataOnStorage(Order);
        })
    }

    setComplement = (addressComplement) => {
        this.setState({
            complement: addressComplement
        }, () => {
            Order.address.complement = addressComplement;
            ConfigService.persistCustomerDataOnStorage(Order);
        })
    }

    setFreight = (freight) => {
        Order.freightOption = freight;
        ConfigService.persistCustomerDataOnStorage(Order);
        this.setState({
            freightOption: freight
        });
    }

    setProvince = (province) => {
        this.setState({
            province: province
        }, () => {
            Order.address.province = province;
            ConfigService.persistCustomerDataOnStorage(Order);
        })
    }

    setAddress = (address) => {
        this.setState({
            addressForm: address
        }, () => {
            Order.address.address = address;
            ConfigService.persistCustomerDataOnStorage(Order);
        })
    }

    render() {

        let freights = [];
        if (this.state.freightOptions) {
            this.state.freightOptions.forEach((freightOption) => {
                freightOption.options.forEach((option) => {
                    freights.push(option);
                })
            })
        }

        return (
            <MasterPage loading={this.state.loading}>
                <Breadcrumb step={"delivery"} />
                <h1 className="page-title">Informe os dados de entrega</h1>
                <div className="delivery">



                    <DefaultPanel className="panel-default-4" title="Endereço">
                        <InputZipcode onSubmit={this.findZipCode.bind(this)} title="Cep" value={this.state.zipCode} loading={this.state.searchingZipCode} validation={this.validateZipCode} />
                        {
                            this.state.addressFound
                                ?
                                <div className="address-box">
                                    <p className="address-item address-item-master">{this.state.city}, {this.state.state}</p>
                                    <p className="address-item">{this.state.address}</p>
                                    <p className="address-item">{this.state.province}</p>
                                </div>
                                :
                                null
                        }
                    </DefaultPanel>
                    <DefaultPanel className="panel-default-4" title={this.state.addressFound ? "Quem irá receber?" : ""}>
                        {
                            this.state.addressFound
                                ?
                                <Fragment>
                                    <InputField onChange={this.setContact.bind(this)} value={this.state.contactName} validation={this.validateFullName} label="Nome completo da pessoa" placeholder="Ex.: João da silva" />
                                    <InputField onChange={this.setNumber.bind(this)} value={this.state.number} validation={this.validateRequired} label="Número do endereço" placeholder="Ex.: 1234" />
                                    <InputField onChange={this.setComplement.bind(this)} value={this.state.complement} label="Complemento" placeholder="Ex.: Ap 907" />
                                    {!this.state.address
                                        ?
                                        <Fragment>
                                            <InputField validation={this.validateRequired} onChange={this.setAddress.bind(this)} value={this.state.addressForm} label="Logradouro" placeholder="Ex.: Rua Visconde Terceiro" />
                                            <InputField validation={this.validateRequired} onChange={this.setProvince.bind(this)} value={this.state.province} label="Bairro" placeholder="Ex.: Bairro dos Imigrantes" />
                                        </Fragment>
                                        :
                                        null
                                    }
                                </Fragment>
                                :
                                null
                        }
                    </DefaultPanel>
                    <DefaultPanel className="panel-default-4" title={this.state.addressFound ? "Escolha um tipo de entrega" : ""}>
                        {
                            this.state.searchingZipCode
                                ?
                                <div>
                                    <h4>Buscando informações de entrega</h4>
                                </div>
                                :
                                <React.Fragment>
                                    {
                                        this.state.freightOptions
                                            ?
                                            freights.map((opt, key) => {
                                                return <div key={key} className="delivery-option">
                                                    <OptionSelector
                                                        isDeliveryOption={true}
                                                        onClick={() => { this.setFreight(opt) }}
                                                        checked={(this.state.freightOption && opt.code === this.state.freightOption.code)}
                                                        name="freight"
                                                        title={opt.name}
                                                        text={`Até ${opt.time} dias úteis: ${StringUtils.priceToText(opt.value)}`}
                                                    />
                                                </div>
                                            })
                                            :
                                            null
                                    }
                                </React.Fragment>
                        }
                    </DefaultPanel>
                </div>
                <div className="button-panel">
                    <LinkButton disabled={!this.forwardAvailable()} to="/payment" text="Prosseguir" />
                </div>
                <Alert show={this.state.alertShow} title={this.state.alertTitle} message={this.state.alertMessage} onClose={this.onAlertClose.bind(this)} />
            </MasterPage>

        );
    }
}
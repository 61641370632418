import React, { Component } from "react";
//import Modal from 'react-modal';
//import Menu from './Menu';

class Header extends Component {
    constructor () {
        super();
        this.state = {
          showModal: false
        };
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
      }
      
      handleOpenModal () {
        this.setState({ showModal: true });
      }
      
      handleCloseModal () {
        this.setState({ showModal: false });
      }

    render() {
        return (
            <header className="header">
                <h1 className="header-logo">
                    <a href="/site" className="logo-img">
                        <span>EasyFotoBrasil</span>
                    </a>
                </h1>
            </header>
        );
    }
}

export default Header;

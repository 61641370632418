import React, { Component } from 'react';
import MasterPage from "../components/layout/MasterPage";
import InputField from '../components/InputField';
import {DefaultButton} from "../components/DefaultButton";

class ResetPass extends Component {
    constructor(props){
        super(props)

        this.state = {
            sendState: false,
        }
    }

    sendPassState(){
        this.setState({sendState: !this.state.sendState});
    }
    render() {
        return (
            <MasterPage>
                
                <h1 className="page-title">Definir uma nova senha</h1>
                <div className="login-box">
                {
                    !this.state.sendState?
                    <div className="login-content">
                        <InputField type="password" label="Nova senha" />
                        <InputField type="password" label="Confirmar nova senha" />
                        <DefaultButton onClick={this.sendPassState.bind(this)} text="Salvar" />
                        </div>
                        :
                        <p className="reset-pass-text">Senha alterada com sucesso.</p>
                }
                </div>
            </MasterPage>
            
        );
    }
}

export default ResetPass;
import S3Policy from './S3Policy';
import Request from './Request';

export default class S3Uploader {
    static upload(file, options) {
        const AWS_DEFAULT_S3_HOST = 's3.amazonaws.com';
        options = Object.assign({}, options, {
            key: (options.keyPrefix || '') + file.name,
            date: new Date(),
            contentType: file.type
        });
        const url = `https://${options.bucket}.${(options.awsUrl || AWS_DEFAULT_S3_HOST)}`;
        const method = "POST";
        const policy = S3Policy.generate(options);
        return (new Request(url, method))
            .setPolicy(policy)
            .setFile(file.file, file.name)
            .send();
    }
}
import React, { Component } from "react";
import Order from "../easyfoto/Order";
import StringUtils from "../easyfoto/StringUtils";

class Breadcrumb extends Component {
	render() {
		const quantity = Order.getItensQuantity();
		const freightPrice = Order.calcFreightPrice();
		const selectedProduct = Order.getSelectedProduct();
		const discountPrice = Order.discountPrice();

		return (
			<section className="breadcrumb">
				<div className="bc_content">
					<div className="bc_cells">
						<div className="bc_cell bc_product">
							<div className="bc_cell_content">
								<p className="bc_title">Total de produtos:</p>
								<p className="bc_value">
									{quantity} foto{quantity > 0 ? "s" : ""}{" "}
								</p>
							</div>
						</div>
						<div className="bc_cell bc_product">
							<div className="bc_cell_content">
								<p className="bc_title">Produto selecionado:</p>
								<p className="bc_value">
									{selectedProduct ? selectedProduct.name : "--"}
								</p>
							</div>
						</div>

						{discountPrice ? (
							<div className="bc_cell bc_discount">
								<div className="bc_cell_content">
									<p className="bc_title">Desconto:</p>
									<p className="bc_value">
										{StringUtils.priceToText(discountPrice)}
									</p>
								</div>
							</div>
						) : null}

						<div className="bc_cell bc_freight">
							<div className="bc_cell_content">
								<p className="bc_title">Frete:</p>
								<p className="bc_value">
									{freightPrice > 0
										? StringUtils.priceToText(freightPrice)
										: "----"}
								</p>
							</div>
						</div>
						<div className="bc_cell bc_total">
							<div className="bc_cell_content">
								<p className="bc_title">Você irá pagar:</p>
								<p className="bc_value">
									{StringUtils.priceToText(Order.calcTotalPrice())}
								</p>
							</div>
						</div>
					</div>
					{this.props.step === "photo" ? (
						<p className="bc_text">
							{quantity < selectedProduct.startQuantity
								? "O pedido deve conter pelo menos " +
								  selectedProduct.startQuantity +
								  " fotos"
								: "Cada foto adicional custa " +
								  StringUtils.priceToText(
										Order.configs.selectedOption &&
											Order.configs.selectedOption.price
								  )}
						</p>
					) : null}
				</div>
			</section>
		);
	}
}

export default Breadcrumb;

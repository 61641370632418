import React, { Component } from 'react';

class Alert extends Component {

    toggle() {
        if (typeof this.props.onClose == 'function')
            this.props.onClose();
    }

    render() {
        let boxClass = ["alert-box"];
        if (!this.props.show) {
            boxClass.push('alert-off');
        }
        return (
            <div>
                <div className={boxClass.join(' ')}>
                    <div className="alert-content">
                        <h3 className="alert-title">{this.props.title}</h3>
                        <p className="alert-description">{this.props.message}</p>
                    </div>
                    <button className="alert-close"
                            onClick={this.toggle.bind(this)}><span>Close</span></button>
                </div>
            </div>
        );
    }
}

export default Alert;
import React, { Component } from "react";

class Footer extends Component {
	render() {
		return (
			<footer className="footer">
				<div className="footer-content">
					<div className="footer-cell footer-cell-logo">
						<h2 className="footer-logo">
							<a href="/site">
								<span>EasyFotoBrasil</span>
							</a>
						</h2>
						<p className="footer-info">
							Suas fotos como você nunca viu. <br /> CNPJ:29.234.300/0001-17
						</p>
					</div>
					<div className="footer-cell">
						<a href="/site" className="footer-link">
							Site
						</a>
						<a href="/site#FAQ" className="footer-link">
							Dúvidas
						</a>
						<a href="/site#PRODUCTION" className="footer-link">
							Detalhes da construção
						</a>
					</div>
					<div className="footer-cell">
						<h3 className="footer-title">Siga-nos</h3>
						<ul className="footer-social">
							<li className="footer-social-item">
								<a
									href="https://www.instagram.com/easyfoto_brasil/"
									target="_blank"
									rel="noopener noreferrer"
									className="footer-social-instagram"
								>
									<span>Instagram</span>
								</a>
							</li>
						</ul>
					</div>
					<div className="footer-cell">
						<h3 className="footer-title">Instale nosso aplicativo</h3>
						<ul className="footer-appstore">
							<li className="footer-appstore-item">
								<a
									href="https://itunes.apple.com/br/app/easyfoto4u/id1298571976?mt=8"
									target="_blank"
									rel="noopener noreferrer"
									className="footer-appstore-apple"
								>
									<span>App Store</span>
								</a>
							</li>
							<li className="footer-appstore-item">
								<a
									href="https://play.google.com/store/apps/details?id=com.easyfoto4u&utm_source=website"
									target="_blank"
									rel="noopener noreferrer"
									className="footer-appstore-google"
								>
									<span>Play Store</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<p className="footer-small-message">
					EasyFotoBrasil - {new Date().getFullYear()} - Todos os direitos
					reservados
				</p>
			</footer>
		);
	}
}

export default Footer;

import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ResizeAndCropService from "../easyfoto/services/ResizeAndCropService";

export class PhotoFrame extends Component {

    constructor(props){
        super(props);

        this.state = {
            photo:ResizeAndCropService.fileToBlobRef(this.props.photo)
        };

    }

    edit = () => {
        this.props.onEditClick();
    };

    remove = () => {
        this.props.onRemoveClick();
    };

    addCopy = () => {
        this.props.addCopyClick();
    };

    removeCopy = () => {
        this.props.removeCopyClick();
    };

    render() {
        return (
            <div className="photo-cell">
                <div className={
                    this.props.lowResolution
                        ?
                        "photo-cell-holder low-resolution"
                        :
                        "photo-cell-holder"
                }
                     title={this.props.lowResolution ? "Foto com baixa resolução" : ""}
                >
                    <img className="photo-cell-image" src={this.state.photo} alt={this.props.fileName} title={this.props.fileName}/>
                </div>
                <div className="photo-cell-actions">
                    <button onClick={() => { this.edit() }} className="photo-cell-button photo-cell-button-edit">
                        <span className="photo-cell-button-icon"><FontAwesomeIcon icon={ faEdit }/> </span>
                        <span className="photo-cell-button-text">Editar</span>
                    </button>
                    <button onClick={() => { this.remove() }} className="photo-cell-button photo-cell-button-remove">
                        <span className="photo-cell-button-icon"><FontAwesomeIcon icon={ faTrashAlt }/> </span>
                        <span className="photo-cell-button-text">Remover</span>
                    </button>
                </div>
                <div className="photo-cell-actions photo-cell-copies">
                    <button onClick={() => { this.removeCopy() }} className="photo-cell-button photo-cell-button-plus">
                        <span className="photo-cell-button-text">-</span>
                    </button>
                    <span className="photo-cell-quantity">{"Cópias: " + this.props.quantity}</span>
                    <button onClick={() => { this.addCopy() }} className="photo-cell-button photo-cell-button-minus">
                        <span className="photo-cell-button-text">+</span>
                    </button>
                </div>
            </div>
        );
    }
}

export class PhotoAddButton extends Component {

    render() {
        return (
            <label className="photo-cell">
                <div className="photo-cell-holder">
                    <div className="photo-cell-button-add">
                        <div className="photo-cell-button-plus"></div>
                        <p className="photo-cell-button-text">Adicionar fotos</p>
                        <input className="hidden" type="file" onChange={this.props.onChange} multiple accept=".jpg, .png" />
                    </div>
                </div>
            </label>
        );
    }

}
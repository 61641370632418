import DateUtils from './DateUtils';
const CryptoJS = require('crypto-js');
const Buffer = require('buffer/').Buffer;

export default class S3Policy {

    static FIVE_MINUTES = (5 * (60 * 1000));
    static AWS_ACL = "public-read";
    static AWS_SERVICE_NAME = "s3";
    static AWS_REQUEST_POLICY_VERSION = "aws4_request";
    static AWS_ALGORITHM = "AWS4-HMAC-SHA256";
    static DEFAULT_SUCCESS_ACTION_STATUS = "201";

    static formatPolicyForRequestBody(base64EncodedPolicy, signature, options) {

        var policy = {
            "key": options.key,
            "success_action_status": options.successActionStatus,
            "Content-Type": options.contentType,
            "X-Amz-Credential": options.credential,
            "X-Amz-Algorithm": options.algorithm,
            "X-Amz-Date": options.amzDate,
            "Policy": base64EncodedPolicy,
            "X-Amz-Signature": signature
        };

        if (options.acl) {
            policy.acl = options.acl;
        }

        return policy;
    }

    static formatPolicyForEncoding(policy) {

        var _policy = {
            "expiration": policy.expirationDate,
            "conditions": [{"bucket": policy.bucket}, {"key": policy.key}, {"success_action_status": policy.successActionStatus}, {"Content-Type": policy.contentType}, {"x-amz-credential": policy.credential}, {"x-amz-algorithm": policy.algorithm}, {"x-amz-date": policy.amzDate}]
        };

        if (_policy.acl) {
            policy.conditions.push({"acl": policy.acl});
        }

        return _policy;
    }

    static getEncodedPolicy(policy) {
        return new Buffer(JSON.stringify(policy), "utf-8").toString("base64");
    }

    static getSignature(base64EncodedPolicy, options) {
        return CryptoJS.HmacSHA256(base64EncodedPolicy, S3Policy.getSignatureKey(options)).toString(CryptoJS.enc.Hex);
    }

    static getSignatureKey(options) {
        var kDate = CryptoJS.HmacSHA256(options.yyyymmddDate, "AWS4" + options.secretKey);
        var kRegion = CryptoJS.HmacSHA256(options.region, kDate);
        var kService = CryptoJS.HmacSHA256(S3Policy.AWS_SERVICE_NAME, kRegion);
        var kSigning = CryptoJS.HmacSHA256(S3Policy.AWS_REQUEST_POLICY_VERSION, kService);

        return kSigning;
    }

    static generate(options) {

        var assert = function assert(object, message) {
            if (null == object) throw new Error(message);
        };

        options || (options = {});

        assert(options.key, "Must provide `key` option with the object key");
        assert(options.bucket, "Must provide `bucket` option with your AWS bucket name");
        assert(options.contentType, "Must provide `contentType` option with the object content type");
        assert(options.region, "Must provide `region` option with your AWS region");
        assert(options.date, "Must provide `date` option with the current date");
        assert(options.accessKey, "Must provide `accessKey` option with your AWSAccessKeyId");
        assert(options.secretKey, "Must provide `secretKey` option with your AWSSecretKey");

        var date = options.date;
        var timeDelta = options.timeDelta || 0;
        var policyExpiresIn = S3Policy.FIVE_MINUTES - timeDelta;
        var expirationDate = new Date(date.getTime() + policyExpiresIn);

        var policyParams = Object.assign({}, options, {
            acl: options.acl,
            algorithm: S3Policy.AWS_ALGORITHM,
            amzDate: DateUtils.dateToString(date, DateUtils.AMZ_ISO8601),
            yyyymmddDate: DateUtils.dateToString(date, DateUtils.YYYYMMDD),
            expirationDate: DateUtils.dateToString(expirationDate, DateUtils.ISO8601),
            successActionStatus: String(options.successActionStatus || S3Policy.DEFAULT_SUCCESS_ACTION_STATUS)
        });

        policyParams.credential = [policyParams.accessKey, policyParams.yyyymmddDate, policyParams.region, S3Policy.AWS_SERVICE_NAME, S3Policy.AWS_REQUEST_POLICY_VERSION].join('/');

        const policy = S3Policy.formatPolicyForEncoding(policyParams);
        const base64EncodedPolicy = S3Policy.getEncodedPolicy(policy);
        const signature = S3Policy.getSignature(base64EncodedPolicy, policyParams);

        return S3Policy.formatPolicyForRequestBody(base64EncodedPolicy, signature, policyParams);

    }
}
import React, { Component, Fragment } from "react";
import InputMask from "react-input-mask";

class InputCupom extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value
		};
	}

	_onSubmit = () => {
		if (this.props.onSubmit) {
			this.props.onSubmit(this.state.value);
		}
	};

	_onChange = event => {
		const value = String(event.target.value || "").toUpperCase();

		if (this.props.onChange) {
			this.props.onChange(value);
		}

		this.setState({
			value
		});
	};

	componentDidMount() {
		if (this.state.value) {
			this._onSubmit();
		}
	}

	render() {
		return (
			<Fragment>
				<label className="form-label">{this.props.title}</label>
				<div className="cupom-wrapper">
					<InputMask
						type="text"
						placeholder="Digite seu código"
						className="form-input cupom"
						onChange={this._onChange.bind(this)}
						value={this.state.value}
					/>
					{this.props.loading ? (
						<button className="button zipcode loading">
							<span>&nbsp;</span>
						</button>
					) : (
						<button
							className="button cupom"
							onClick={this._onSubmit.bind(this)}
						>
							<span>Enviar</span>
						</button>
					)}
				</div>
			</Fragment>
		);
	}
}

export default InputCupom;

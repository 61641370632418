import React, { Component, Fragment } from 'react';

class OptionSelector extends Component {

    render() {

        return (
            <Fragment>
                <button className={"option-selector " + (this.props.checked?'checked':'unchecked') } onClick={this.props.onClick.bind(this)}>
                    <span className="checkmark"></span>
                    <span className="option-content">
                        <span className="option-title">{this.props.title}</span>
                        <span className="option-text">{this.props.text}</span>
                    </span>
                </button>
            </Fragment>
        );
    }
}

export default OptionSelector;
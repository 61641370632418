import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import Terms from "./pages/Terms";
import SelectProduct from "./pages/SelectProductScene";
import PhotoScene from "./pages/PhotoScene";
import DeliveryScene from "./pages/DeliveryScene";
import PaymentScene from "./pages/PaymentScene";
import SuccessScene from "./pages/SuccessScene";
import NotFoundScene from "./pages/NotFoundScene";

import Login from "./pages/Login";
import MyOrder from './pages/MyOrder';
import DetailOrder from './pages/DetailOrder';
import PersonalData from './pages/PersonalData';
import LostPass from "./pages/LostPass";
import ResetPass from "./pages/ResetPass";
import Register from "./pages/Register";
import RegisterPersonalData from "./pages/RegisterPersonalData";
import RegisterComplete from "./pages/RegisterComplete";

import FaqScene from "./pages/FaqScene";
import DesignScene from "./pages/DesignScene";

import { initKonduto } from "./assets/js/konduto";

class App extends Component {

    componentWillMount() {
        initKonduto();
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={SelectProduct} />
                    <Route exact path="/site" component={Home} />
                    <Route exact path="/termos-de-uso" component={Terms} />
                    <Route exact path="/termos-de-uso.html" component={Terms} />
                    <Route exact path="/select-product" component={SelectProduct} />
                    <Route exact path="/start" component={PhotoScene} />
                    <Route exact path="/delivery" component={DeliveryScene} />
                    <Route exact path="/payment" component={PaymentScene} />
                    <Route exact path="/success/:orderId" component={SuccessScene} />

                    <Route exact path="/duvidas" component={FaqScene} />
                    <Route exact path="/producao" component={DesignScene} />

                    <Route exact path="/login" component={Login} />
                    <Route exact path="/my-order" component={MyOrder} />
                    <Route exact path="/detail-order" component={DetailOrder} />
                    <Route exact path="/personal-data" component={PersonalData} />
                    <Route exact path="/lost-pass" component={LostPass} />
                    <Route exact path="/reset-pass" component={ResetPass} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/register-personal-data" component={RegisterPersonalData} />
                    <Route exact path="/register-complete" component={RegisterComplete} />

                    <Route component={NotFoundScene} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;

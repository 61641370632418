
import Cfg from '../Config';
import MainService from './MainService';
import HttpService from './HttpService';

export default class DeliveryService extends MainService{

    static findZipcodeInfos(zipcode, sku, quantity, callback){

        var zip = zipcode.replace(/[^0-9]/g, '');
        var url = Cfg.API.url + 'bff/pre-checkout/'+zip+'/with/'+sku+'?quantity=' + quantity;

        console.debug("url",url);

        return HttpService.axios.request({
            method: "get",
            url: url
        });

    }


}
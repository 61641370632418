import Cfg from "../Config";
import MainService from "./MainService";
import HttpService from "./HttpService";
import OrderConfigs from "../OrderConfigs";
import Storage from "../Storage";

export default class ConfigService extends MainService {
	static refreshConfigs(order) {
		console.debug("ConfigService: refreshConfigs");

		order.configs = new OrderConfigs();

		return new Promise((resolve, reject) => {
			//criando o valor base
			ConfigService.loadCachedConfigsValue(order).then(() => {
				console.debug("ConfigService: loadCachedConfigsValue");

				HttpService.axios
					.get(Cfg.API.url + "bff/products")
					.then(res => {
						if (res.data && res.data.length > 0) {
							order.configs = {
								selectedOption: res.data[0],
								options: res.data
							};
							Storage.saveInDB(Storage.KEYS.CONFIG, order.configs)
								.then(() =>
									console.debug(
										"ConfigService: Configs data saved on storage",
										order.configs
									)
								)
								.catch(e => console.debug("ConfigService: error on storage", e));
							resolve();
						}
					})
					.catch(err => {
						console.debug("error", err);
						ConfigService.loadCachedConfigsValue(order, () => {
							resolve();
						});
					});
			});
		});
	}

	static loadCachedConfigsValue(order) {
		console.debug("ConfigService: loadCachedConfigsValue");
		return new Promise((resolve, reject) => {
			Storage.loadFromDB(Storage.KEYS.CONFIG)
				.then(value => {
					console.debug("ConfigService: recovering from storage", value);
					if (value) {
						order.configs = value;
					}
					resolve();
				})
				.catch(e => {
					console.debug("ConfigService: error recovering", e);
					resolve();
				});
		});
	}

	static persistCustomerDataOnStorage(order) {
		console.debug("ConfigService: persistCustomerDataOnStorage");
		const data = {
			personalData: order.personalData,
			address: order.address,
			freightOption: order.freightOption,
			paymentApiResult: order.paymentApiResult
		};

		Storage.saveInDB(Storage.KEYS.CUSTOMER_DATA, data)
			.then(() =>
				console.debug("ConfigService: customer data saved on storage", data)
			)
			.catch(e => console.debug("ConfigService: error on storage", e));
	}

	static getCustomerDataFromStorage() {
		console.debug("ConfigService: getCustomerDataFromStorage");
		return new Promise((resolve, reject) => {
			Storage.loadFromDB(Storage.KEYS.CUSTOMER_DATA)
				.then(value => {
					console.debug("ConfigService: customerData", value);
					resolve(value);
				})
				.catch(e => {
					console.debug("ConfigService: error recovering", e);
					resolve();
				});
		});
	}
}

import React, { Component } from 'react';
import MasterPage from "../components/layout/MasterPage";
import { Link } from "react-router-dom";

class RegisterComplete extends Component {
    render() {
        return (
            <MasterPage>
                
                <h1 className="page-title">Seja bem-vindo</h1>
                <div className="login-box">
                    <div className="login-content">
                        <h3 className="register-title bold">Sua conta foi criada com sucesso!</h3>
                        <p className="register-title">O que deseja fazer agora?</p>
                        <Link to="/" className="button">Adicionar fotos</Link>
                        <Link to="/personal-data" className="button">Ir para dados pessoais</Link>
                    </div>
                </div>
            </MasterPage>
            
        );
    }
}

export default RegisterComplete;
import React, { Component } from "react";
import MasterPage from "../components/layout/MasterPage";
import ConfigService from "../easyfoto/services/ConfigService";
import Order from "../easyfoto/Order";

export default class SuccessScene extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderId: "loading",
			loading: false
		};
	}

	componentDidMount() {
		this.setState({ loading: true });

		ConfigService.refreshConfigs(Order).then(() => {
			ConfigService.getCustomerDataFromStorage()
				.then(customerData => {
					if (customerData) {
						if (customerData.paymentApiResult) {
							this.setState({
								orderId: customerData.paymentApiResult.orderId,
								product:
									customerData.paymentApiResult.items[0].productStore.name,
								quantity: customerData.paymentApiResult.items[0].quantity,
								productTotalPrice:
									customerData.paymentApiResult.items[0].totalPrice,
								zipCode: customerData.address.zipCode,
								freight: customerData.paymentApiResult.freightTotal,
								totalPrice: customerData.paymentApiResult.total,
								loading: false
							});
							console.debug(customerData);
						}
					}
				})
				.catch(err => {
					console.debug(err);
				});
		});
	}

	formatPrice(price) {
		return Number(price || 0)
			.toFixed(2)
			.replace(".", ",");
	}

	formatZipCode(zipcode) {
		if (zipcode) {
			let code = zipcode;
			let p1 = code.slice(0, 5);
			let p2 = code.slice(5, 8);
			let res = p1 + "-" + p2;
			return res;
		}
		return null;
	}

	render() {
		return (
			<MasterPage loading={this.state.loading}>
				<h1 className="page-title">Pedido #{this.state.orderId}</h1>
				<div className="order">
					<div className="order-box">
						<div className="order-col">
							<div className="product-image">
								<span>Box product</span>
							</div>
						</div>
						<div className="order-col">
							<table className="order-table">
								<tr className="order-table-line">
									<td className="order-td">
										<strong>{this.state.quantity}x</strong> {this.state.product}
									</td>
									<td className="order-td">
										<strong>
											R$ {this.formatPrice(this.state.productTotalPrice)}
										</strong>
									</td>
								</tr>
								<tr className="order-table-line">
									<td className="order-td">
										Frete para cep{" "}
										<strong>{this.formatZipCode(this.state.zipCode)}</strong>
									</td>
									<td className="order-td">
										<strong>R$ {this.formatPrice(this.state.freight)}</strong>
									</td>
								</tr>
								<tr className="order-table-line">
									<td className="order-td">Total da compra</td>
									<td className="order-td">
										<strong>
											R$ {this.formatPrice(this.state.totalPrice)}
										</strong>
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div className="order-info">
						<p className="order-info-p-strong">
							Você receberá um email com todas as informações do pedido (valor,
							prazo para entrega, etc)
						</p>
						<p className="order-info-p">
							Fique atento para a previsão de entrega do pedido, que é de:{" "}
							<span>até 12 dias úteis após a confirmação de pagamento</span>
						</p>
						<p className="order-info-attention">
							Após a confirmação de pagamento do seu pedido, iniciaremos a
							confecção dos produtos, este processo inclui diversas etapas,
							como: Impressão, Corte, Montagem, Acabamento, Controle de
							Qualidade e Expedição.
						</p>
						<p className="order-info-p-strong">
							<a
								href="/site#PRODUCTION"
								target="_blank"
								rel="noopener noreferrer"
							>
								Saiba mais sobre nosso processo de produção
							</a>
						</p>
					</div>
				</div>
			</MasterPage>
		);
	}
}

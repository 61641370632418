import S3Uploader  from '../S3Uploader/S3Uploader';
import Cfg from '../Config';
const b64toBlob = require('b64-to-blob');

export default class UploadService {

    static uploadBase64Image(base64String, nameOnBucket) {
        console.debug("UploadService: converting Base64 Image to Blob");
        const i = base64String.replace(/^data:image\/[a-z]+;base64,/, "");
        return UploadService.uploadBlob(b64toBlob(i, "image/jpeg"), nameOnBucket);
    }

    static uploadBlob(blob, nameOnBucket) {
        console.debug("UploadService: converting Blob Image to File");
        return UploadService.uploadFile(new File([blob], "foo.jpg", {
            type: "image/jpeg",
        }), nameOnBucket)
    }

    static uploadFile(file, nameOnBucket) {
        console.debug("UploadService: sending File to Source");
        const imgToUpload = {
            file: file,
            name: nameOnBucket,
            type: 'image/jpeg'
        };

        return S3Uploader.upload(imgToUpload, Cfg.AWSOPTIONS);
    }
}
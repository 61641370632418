import React, { Component } from "react";
import "../assets/sass/home.scss";
import { toStringCurrency } from "../helpers/string";
import queryString from "query-string";

class Home extends Component {
	frames = [
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
		13,
		14,
		15,
		16,
		17,
		18,
		19,
		20,
		21,
		22,
		23,
		24,
		25
	];

	unit = 200;
	add = 35;

	render() {
		const {
			location: { search }
		} = this.props;

		const { r: couponLink } = queryString.parse(search);

		if (couponLink) {
			localStorage.setItem("r", couponLink);
		}

		return (
			<div className="home">
				<header className="home_header">
					<div className="container">
						<h1 className="site-name">
							<span>EasyFotoBrasil</span>
						</h1>
						<h2 className="site-subtitle">
							Suas fotos como você nunca viu.
							<br />
							Faça seus momentos serem muito mais reais <br /> que a tela de um
							smartphone.
						</h2>
					</div>
				</header>
				<div className="content">
					<div className="preview">
						<div className="container">
							<h2>Instale, escolha, compre e receba.</h2>
							<span className="links">
								<a
									className="store-link store-webapp"
									href="/select-product"
									rel="external"
									title="Utilize Online"
								>
									<span>Disponível também online no site</span>
								</a>
							</span>
							<span className="links">
								<a
									className="store-link store-apple"
									target="_blank"
									rel="noopener noreferrer"
									href="https://itunes.apple.com/br/app/easyfoto4u/id1298571976?mt=8"
									title="Acesso ao Aplicativo na loja da Apple"
								>
									<span>Link para Loja Apple Store</span>
								</a>
								<a
									className="store-link store-android"
									target="_blank"
									rel="noopener noreferrer"
									href="https://play.google.com/store/apps/details?id=com.easyfoto4u&utm_source=website"
									title="Acesso ao Aplicativo na loja do Google"
								>
									<span>Link para Loja Google Play</span>
								</a>
							</span>
							<span className="smartphone" />
							<p>
								Escolha as fotos dos momentos mais marcantes, ajuste as no
								próprio aplicativo e envie pra nós. Você receberá seu produto
								(quadro ou imã) em casa!
							</p>
						</div>
					</div>
					<div className="highlight">
						<div className="container">
							<h2>Bons momentos merecem ser compartilhados.</h2>
							<p>
								Com EasyFotoBrasil você pode presentear pessoas importantes com
								momentos felizes que passaram juntos: aquela festa da família,
								um encontro especial ou aquela viagem inesquecível. <br /> As
								possibilidades são infinitas!
							</p>
						</div>
					</div>
					<div className="differences">
						<h3>
							O que faz <strong>&nbsp;EasyFotoBrasil&nbsp;</strong> ser tão
							diferente?
						</h3>

						<div className="grid-row container">
							<div className="grid-cell-4">
								<h4>
									<strong>
										Além de uma foto, uma experiência <br /> marcante em cada
										quadro/imã
									</strong>
								</h4>
								<p className="dashed">
									Cada fotografia escolhida contém uma emoção única, vivida em
									um momento muito especial de sua vida! <br />
									Olhar para um quadro/imã significa reviver lembranças reais.
								</p>
							</div>
							<div className="grid-cell-4">
								<h4>
									<strong>
										Atenção nos detalhes do produto, <br /> da produção até a
										entrega
									</strong>
								</h4>
								<p className="dashed">
									Temos uma grande preocupação com o material utilizado, o
									acabamento das peças, a entrega e tudo mais para que você não
									tenha que se preocupar. <br />
									Nossa intenção é que você gaste o seu tempo naquilo que é
									realmente necessário ao apreciar uma de nossas peças.
								</p>
							</div>
							<div className="grid-cell-4">
								<h4>
									<strong>
										Mova, remova e cole <br /> quantas vezes você quiser.*
									</strong>
								</h4>
								<p className="dashed">
									Nossos quadros possuem uma tecnologia que dispensa o uso de
									furações em paredes, <br /> esqueça toda a dificuldade de
									pregar um quadro na parede. <br />
									Você pode montar infinitas possibilidades organizando os
									quadros em diversas combinações.
								</p>
							</div>
						</div>
					</div>
					<div className="examples">
						<div className="container">
							<h3>Experimente infinitas combinações</h3>
						</div>
					</div>
					<div className="faq" id="FAQ">
						<h2 className="mb">
							Tire suas <strong>&nbsp;dúvidas&nbsp;</strong> mais importantes
						</h2>
						<div className="col-sm-12">
							<div className="container">
								<h3 id="o-que-a-easyfoto4u-oferece-">
									O que a EasyFotoBrasil oferece?
								</h3>
								<p>
									Oferecemos o serviço de transformar fotos em quadros ou ímã de
									geladeira, dando vida as suas fotos e ajudando você a decorar
									seus ambientes ! Isso dá a oportunidade de colocar na
									parede/geladeira (ou placa imantada) o registro de um momento,
									uma recordação que nos traz apresso e afetividade.
								</p>
								<h3 id="como-utilizo-o-servi-o-">Como utilizo o serviço?</h3>
								<p>
									Temos 2 canais de venda: aplicativo Easyfotobrasil ou site
									www.easyfotobrasil.com
									<br />
									<br />
									Para baixar nosso aplicativo, entre na loja (Google ou Apple)
									do seu celular e procure por Easyfotobrasil ou se preferir
									acesse a camera do seu celular e foque no QRCode abaixo:
									<br />
									<br />
									<img
										src="/easyapp.png"
										alt="qrcode_app"
										style={{ objectFit: "contain" }}
									/>
								</p>

								<h3 id="pago-alguma-coisa-para-baixar-o-aplicativo-">
									Pago alguma coisa para baixar o aplicativo?
								</h3>
								<p>Não. O download do aplicativo é gratuito.</p>
								<h3 id="como-fa-o-para-comprar-miniquadros-">
									Como faço para comprar quadros?
								</h3>
								<p>
									Temos 2 opções:
									<br />
									<br />
									Opção 1: Após ter baixado nosso aplicativo Easyfotobrasil nas
									lojas Google ou Apple no seu celular, clique no botão começar,
									selecione o produto quadro, escolha as fotos que deseja
									transformar em quadro (mínimo de 5). Ajuste as fotos e informe
									dados para entrega e pagamento. Após isso, basta finalizar
									compra.
									<br />
									<br />
									Opção 2: Acesse o site easyfotobrasil.com, clique no botão no
									botão começar, selecione o produto quadro, escolha as fotos
									que deseja transformar em quadro (mínimo de 5). Ajuste as
									fotos e informe dados para entrega e pagamento. Após isso,
									basta finalizar compra.
								</p>
								<h3 id="como-fa-o-para-comprar-miniquadros-">
									Como faço para comprar ímã de geladeira?
								</h3>
								<p>
									Temos 2 opções:
									<br />
									<br />
									Opção 1: Após ter baixado nosso aplicativo Easyfotobrasil nas
									lojas Google* ou Apple no seu celular, clique no botão
									começar, selecione o produto ímã de geladeira, escolha as
									fotos que deseja transformar em imã (mínimo de 9). Ajuste as
									fotos e informe dados para entrega e pagamento. Após isso,
									basta finalizar compra
									<br />
									<br />
									Opção 2: Acesse o site easyfotobrasil.com, clique no botão no
									botão começar, selecione o produto imã de geladeira, escolha
									as fotos que deseja transformar em imã (mínimo de 9). Ajuste
									as fotos e informe dados para entrega e pagamento. Após isso,
									basta finalizar compra.
								</p>
								<h3 id="posso-enviar-os-miniquadros-para-outras-pessoas-como-um-presente-">
									Posso enviar os produtos da Easyfoto (quadro ou imã de
									geladeira) para outras pessoas como um presente?
								</h3>
								<p>
									Claro! Basta informar no aplicativo ou no site o endereço
									completo para entrega e nome do destinatário.
								</p>
								<h3 id="como-fa-o-o-pagamento-do-servi-o-">
									Como faço o pagamento do serviço?
								</h3>
								<p>
									Até o momento só estamos aceitando cartões de crédito. É
									importante que o endereço informado nos dados para pagamento
									seja o endereço de correspondência do cartão de crédito
									informado para efetivação do pedido de compra.
								</p>
								<h3 id="quanto-custa-cada-quadro-">
									O pagamento pode ser parcelado
								</h3>
								<p>
									Sim, pode parcelar em até 3x sem juros no cartão de crédito
									desde que cada parcela tenha R$ 50,00 como valor mínimo. Veja
									simulações na tabela abaixo:
								</p>
								<table>
									<thead>
										<tr>
											<th>Valor do pedido</th>
											<th>Pagamento</th>
										</tr>
									</thead>
									<tbody id="prices">
										<tr>
											<td>até R$ 99,99</td>
											<td>
												Não tem parcelamento. Pagamento em parcela única do
												valor integral do pedido no cartão de crédito
											</td>
										</tr>
										<tr>
											<td>R$ 100,00 até R$ 149,99</td>
											<td>
												Pode parcelar em até 2x sem juros no cartão de crédito
											</td>
										</tr>
										<tr>
											<td>Acima R$ 150,00</td>
											<td>
												Pode parcelar em até 3x sem juros no cartão de crédito
											</td>
										</tr>
									</tbody>
								</table>
								<h3 id="como-vejo-o-andamento-do-meu-pedido-">
									Como vejo o andamento do meu pedido?
								</h3>
								<p>
									O usuário irá receber três emails que informarão o andamento
									de sua solicitação:
								</p>
								<ul>
									<li>a) Pedido de compra realizado;</li>
									<li>b) Pedido de compra aprovado;</li>
									<li>c) Pedido de compra enviado.</li>
								</ul>
								<h3 id="posso-obter-reembolso-se-algum-miniquadro-chegar-danificado-ou-se-eu-simplesmente-n-o-gostar-">
									Posso obter reembolso se algum produto chegar danificado ou se
									eu simplesmente não gostar?
								</h3>
								<p>
									Nossa garantia de satisfação do cliente dá direito a
									reimpressão ou ao reembolso caso você não esteja 100%
									satisfeito.
								</p>
								<h3 id="quais-fotos-posso-utilizar-">
									Quais fotos posso utilizar?
								</h3>
								<p>
									Não há restrições, vai depender do canal de venda que está
									utilizando. Se tiver usando nosso aplicativo será solicitado
									acesso as fotos contidas no seu dispositivo.
									<br /> Se tiver usando nosso site poderá utilizar as fotos
									armazenadas no seu computador, na nuvem, pen drive e HD
									externo.
								</p>
								<h3 id="quanto-custa-cada-quadro-">
									Quanto custa cada quadro?
								</h3>
								<p>
									Nosso kit mínimo por pedido, com 5 quadros, custa R$200,00.
									<br />
									Peças adicionais custam  R$35,00 quando adicionadas no mesmo
									pedido.
									<br /> Veja a tabela com simulações dos preços:
								</p>
								<table>
									<thead>
										<tr>
											<th>Quantidade</th>
											<th>Kit Mínimo</th>
											<th>Peça adicional</th>
											<th>Valor do kit</th>
										</tr>
									</thead>
									<tbody id="prices">
										<tr>
											<td>5 quadros</td>
											<td>5</td>
											<td>0</td>
											<td>R$ 200,00</td>
										</tr>
										{this.frames.map(x => (
											<tr key={"frame" + x}>
												<td>{x + 5} quadros</td>
												<td>5</td>
												<td>{x}</td>
												<td>{toStringCurrency(this.unit + x * this.add)}</td>
											</tr>
										))}
									</tbody>
								</table>

								<h3 id="quanto-custa-cada-quadro-">
									Quanto custa cada imã de geladeira?
								</h3>
								<p>
									Nosso kit mínimo por pedido, com 9 ímãs, custa R$29,90. <br />
									Peças adicionais custam  R$2,70 quando adicionadas no mesmo
									pedido.
									<br />
									Veja a tabela com simulações dos preços:
								</p>
								<table>
									<thead>
										<tr>
											<th>Quantidade</th>
											<th>Kit Mínimo</th>
											<th>Peça adicional</th>
											<th>Valor do kit</th>
										</tr>
									</thead>
									<tbody id="prices">
										<tr>
											<td>9 imãs</td>
											<td>9</td>
											<td>0</td>
											<td>R$ 29,90</td>
										</tr>
										{this.frames.map(x => (
											<tr key={"frame" + x}>
												<td>{x + 9} imãs</td>
												<td>9</td>
												<td>{x}</td>
												<td>{toStringCurrency(29.9 + x * 2.7)} </td>
											</tr>
										))}
									</tbody>
								</table>

								<h3 id="tenho-como-comprar-pe-as-adicionais-sem-comprar-o-kit-m-nimo-">
									Tenho como comprar peças adicionais sem comprar o kit mínimo?
								</h3>
								<p>
									Não, as peças adicionais só podem ser adquiridas junto com um
									kit mínimo.
								</p>
								<h3 id="qual-a-medida-do-miniquadro-">
									Qual a medida dos produtos (quadro ou ímã)?
								</h3>
								<p>
									Cada quadro possui 20cm x 20cm. A espessura é de 0,5cm.
									<br />
									Cada imã possui 5cm x 5cm. A espessura é de 0,3mm.
								</p>
								<h3 id="existem-miniquadros-com-outras-medidas-">
									Existem produtos (quadro ou ímã) com outras medidas?
								</h3>
								<p>Ainda não, mas em breve teremos novidades.</p>
								<h3 id="qual-a-cor-da-parte-lateral-e-da-parte-de-tr-s-do-miniquadro-">
									Qual a cor da parte lateral e da parte de trás do quadro?
								</h3>
								<p>
									A região da parte lateral (espessura 0,5cm) e as costas do
									quadro são na cor preta.
								</p>
								<h3 id="existe-op-o-de-cor-da-parte-lateral-ou-da-parte-de-tr-s-do-miniquadro-">
									Existe opção de cor da parte lateral ou da parte de trás do
									quadro?
								</h3>
								<p>
									Não, todos os quadros são elaborados com a mesma cor preta.
								</p>
								<h3 id="em-quanto-tempo-meu-pedido-de-compras-chegar-no-endere-o-de-entrega-">
									Em quanto tempo meu pedido de compras chegará no endereço de
									entrega?
								</h3>
								<p>
									O tempo médio de produção é de até 7 dias úteis após a
									confirmação do pagamento. Acrescente a este prazo o tempo
									médio de entrega dos Correios.
								</p>
								<h3 id="como-eu-colo-o-miniquadro-na-parede-">
									Como eu colo o quadro na parede?
								</h3>
								<p>
									Destaque o papel protetor de cada uma das 2 etiquetas adesivas
									contidas na parte de trás de cada peça e cole na parede.
								</p>
								<h3 id="posso-colar-o-miniquadro-em-qualquer-tipo-de-parede-">
									Posso colar o quadro em qualquer tipo de parede?
								</h3>
								<p>
									Preferencialmente em paredes lisas. Não recomendamos colar os
									quadros em paredes sujas, com umidade, papel de parede e com
									qualquer tipo de textura.
								</p>
								<h3 id="uma-vez-colado-posso-remover-o-miniquadro-de-lugar-">
									Uma vez colado, posso remover o quadro de lugar?
								</h3>
								<p>
									Sim, o quadro foi projetado para ser removível. Basta
									descolá-lo com cuidado da parede e colá-lo em outro lugar. Em
									algumas situações de exceção ao descolar o quadro o adesivo
									pode ficar colado na parede, neste caso basta descolar o
									adesivo da parede e coloca-lo novamente no quadro. Você pode
									experimentar diferentes arranjos. Dê asas a sua imaginação e
									criatividade.
								</p>
								<h3 id="o-miniquadro-ir-estragar-minhas-paredes-">
									O quadro irá estragar minhas paredes?
								</h3>
								<p>Não, o material não provoca nenhum dano às paredes.</p>
								<h3 id="o-miniquadro-pode-ser-colado-em-qualquer-ambiente-">
									O quadro pode ser colado em qualquer ambiente?
								</h3>
								<p>
									Sim, porém não recomendamos a exposição do quadro em ambientes
									onde existam excesso de umidade, luz solar, calor excessivo ou
									exposição ao ar livre sem nenhum tipo de cobertura ou
									proteção.
								</p>
								<h3 id="existe-uma-recomenda-o-m-nima-para-a-foto-">
									Existe uma recomendação mínima para a foto?
								</h3>
								<p>
									Para o quadro o arquivo deve possuir pelo menos 1200 por 1200
									pixels. Fotos de baixa resolução resultarão em quadros com
									pouca nitidez ou granularidade no resultado final. O
									Aplicativo irá informar ao usuário se a foto selecionada
									apresenta a qualidade mínima necessária. Entretanto, se o
									usuário ainda assim mantiver a seleção não poderemos nos
									responsabilizar pela qualidade do produto.
								</p>
								<h3 id="posso-ajustar-minhas-fotos-">
									Posso ajustar minhas fotos?
								</h3>
								<p>
									Claro! Tanto nosso aplicativo quanto o site tem opção de
									edição de cada foto selecionada. Selecione a foto que deseja
									ajustar e enquadre a foto como deseja que seja feita a
									impressão.
								</p>
								<h3 id="quais-sistemas-operacionais-o-aplicativo-j-suporta-">
									Quais sistemas operacionais o aplicativo suporta?
								</h3>
								<p>
									Atualmente o aplicativo pode ser instalado nos sistemas
									operacionais Android e IOS.
								</p>

								<h3 id="voc-s-entregam-em-todo-o-brasil-">
									O site funciona em todos os browsers?
								</h3>
								<p>
									O site está homologado somente para Firefox, Chrome e Safari.
								</p>

								<h3 id="voc-s-entregam-em-todo-o-brasil-">
									Vocês entregam em todo o Brasil?
								</h3>
								<p>
									Sim, entregamos em todos os lugares onde o Correios possui
									logística de entrega.
								</p>
								<h3 id="voc-s-realizam-entrega-internacional-">
									Vocês realizam entrega internacional?
								</h3>
								<p>Ainda não, mas caso haja interesse entre em contato.</p>
							</div>
						</div>
					</div>
					<div className="faq" id="PRODUCTION">
						<h2 id="entrega" className="mb">
							Como os <strong>&nbsp;quadros&nbsp;</strong> são elaborados?
						</h2>
						<div className="col-sm-12">
							<div className="container">
								<h3 id="pr-produ-o">Pré produção</h3>
								<p>
									Tudo começa a partir de uma diagramação feitas com todas as
									fotos que você nos enviou através do aplicativo. Essa fase é
									necessária pois a para que o processo seja rápido, precisamos
									escolher a melhor forma de distribuir os arquivos para a
									máquina de impressão.
								</p>
								<h3 id="produ-o-da-matriz">Produção da Matriz</h3>
								<p>
									Depois de diagramadas, as fotos passam pela máquina de
									impressão, produzindo assim uma peça única que chamamos de
									matriz. A matriz contém as fotos que você nos enviou agregadas
									em um bloco, evitando que se misture com as demais.
								</p>
								<h3 id="controle-de-qualidade-de-impress-o">
									Controle de qualidade de impressão
								</h3>
								<p>
									Ainda na fase de matriz, temos o nosso primeiro processo de
									controle de qualidade. Aqui são verificadas possíveis falhas
									no processo de colorização. Também é feita uma comparação com
									as fotos enviadas pelo aplicativo para assegurar que o produto
									que chegar em suas mãos seja exatamente como esperado.
								</p>
								<h3 id="corte">Corte</h3>
								<p>
									Após aprovação do controle de qualidade, as matrizes são
									enviadas para o corte. Aqui nasce cada quadro em seu formato
									bruto. Cada matriz, ao passar pela máquina de corte, torna-se
									um grupo de quadros ainda sem acabamento.
								</p>
								<h3 id="montagem">Montagem</h3>
								<p>
									Realizado o corte, chegou a hora da montagem. Aqui são
									incluídos os materiais que fazem parte do nosso conhecido
									produto. Esses materiais são utilizados através de um padrão
									seguido rigorosamente durante este processo.
								</p>
								<h3 id="controle-de-qualidade-de-montagem">
									Controle de qualidade de montagem
								</h3>
								<p>
									Agora que os nossos quadros já se parecem com o produto final,
									é feita uma verificação do acabamento, alinhamento e
									estrutura. O pacote agora está pronto para seguir em frente.
								</p>
								<h3 id="embalagem-e-expedi-o">Embalagem e Expedição</h3>
								<p>
									Os quadros agora são acondicionados em caixas para transporte.
									Nesse processo há também um padrão a ser usado para que os
									quadros, mesmo sendo feitos de um material leve e resistente,
									não sejam danificadas durante o transporte.
								</p>
							</div>
						</div>
					</div>
				</div>
				<footer>
					<div className="row centered">
						<h2>Entre em contato conosco</h2>
						<h5>
							<a href="mailto:atendimento@easyfotobrasil.com">
								atendimento@easyfotobrasil.com
							</a>
						</h5>
						<p>
							<a
								href="https://www.instagram.com/easyfoto_brasil/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i className="ion-social-instagram" />
							</a>
						</p>
						<h6 className="rights">EASYFOTOBRASIL - CNPJ:29.234.300/0001-17</h6>
					</div>
				</footer>
			</div>
		);
	}
}

export default Home;

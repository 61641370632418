import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export class DefaultButton extends Component {

    getClassName = () => {
        let classList = ["button"]

        if (this.props.disabled) {
            classList.push('disabled')
        }

        return classList.join(' ');
    }

    buttonClick = () => {
        if (!this.props.disabled && !this.props.loading) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <Fragment>
                <button  {...this.props}
                    className={this.getClassName()}
                    onClick={()=>{this.buttonClick()}}>
                    {
                        (this.props.loading)
                            ?
                                <Fragment>
                                    <FontAwesomeIcon spin={true} icon={ faSpinner } />
                                    <span> {this.props.text}</span>
                                </Fragment>

                            :
                            <span>{this.props.text}</span>
                    }
                </button>
            </Fragment>

        );
    }
}

export class LinkButton extends Component {

    getClassName = () => {
        let classList = ["button"]

        if (this.props.disabled) {
            classList.push('disabled')
        }

        return classList.join(' ');
    }

    render() {
        return (
            <Fragment>
                {
                    this.props.disabled
                        ?
                        <button  {...this.props} className={this.getClassName()}>
                            {
                                (this.props.loading)
                                    ?
                                    <FontAwesomeIcon icon={ faSpinner }/>
                                    :
                                    <span>{this.props.text}</span>
                            }
                        </button>
                        :
                        <Link  {...this.props} className={this.getClassName()}>
                            {
                                (this.props.loading)
                                    ?
                                    <FontAwesomeIcon icon={ faSpinner }/>
                                    :
                                    <span>{this.props.text}</span>
                            }
                        </Link>
                }
            </Fragment>

        );
    }
}
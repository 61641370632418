import React, { Component } from 'react';
import InputMask from "react-input-mask";

class InputZipcode extends Component {

    constructor(props) {
        super(props);

        const notImplementedValidation = (v) => {
            console.debug('InputField: Validation not implemented');
        }

        this.state = {value: props.value || ''};
        this.validate = props.validation || notImplementedValidation;
    }

    handleChange(event) {

        const newState = {value: event.target.value};
        const validationResult = this.validate(event.target.value);

        if (validationResult) {
            if (!validationResult.valid) {
                newState.validationMessage = validationResult.message;
            } else {
                newState.validationMessage = null;
            }
        }

        this.setState(newState);

    }

    handleSubmit(event) {
        this.props.onSubmit(this.state.value);
        event.preventDefault();
    }

    render() {
        let classes = "form-input zipcode";
        if(this.state.validationMessage){
            classes += ' error'
        }
        return (
            <div className="form-zipcode">
                <label className="form-label" htmlFor="zipcode">{this.props.title}</label>
                <form className="zipcode-wrapper" onSubmit={this.handleSubmit.bind(this)}>
                    <InputMask
                        id="zipCode"
                        mask="99999-999"
                        type="text"
                        placeholder="25123-123"
                        className={classes}
                        value={this.state.value}
                        readOnly={this.props.loading}
                        onChange={this.handleChange.bind(this)}
                    />
                    {
                        this.props.loading
                            ?
                            <button className="button zipcode loading"><span>&nbsp;</span></button>
                            :
                            <button onClick={this.handleSubmit.bind(this)} className="button zipcode"><span>Buscar</span></button>
                    }

                </form>
                {
                    this.state.validationMessage
                        ?
                        <span className="form-validation">{this.state.validationMessage}</span>
                        :
                        null
                }
            </div>
        );
    }
}

export default InputZipcode;
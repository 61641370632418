import React, { Component } from 'react';
import MasterPage from "../components/layout/MasterPage";
import { DefaultPanel } from '../components/Panels';
import { Link } from 'react-router-dom';

const MyOrderTitle = {
            title1: <p className="my-order-title">Pedido <span className="bold">136455</span> - <span className="purchase-date">Comprado em 25/01/2019</span></p>,
            title2: <p className="my-order-title my-order-title-past">Pedido 136455 - Comprado em 25/01/2019</p>
        };

class MyOrder extends Component {
    render() {
        return (
            <MasterPage>
                
                <h1 className="page-title">Meus Pedidos</h1>
            <div className="order my-order">
                <DefaultPanel title={MyOrderTitle.title1}>
                    <div className="order-box my-order-box">
                        
                        <div className="order-col">
                            <div className="product-image"><span>Box product</span></div>
                            <Link to="/detail-order" className="button">Abrir pedido</Link>
                        </div>
                        <div className="order-col">
                        <table className="order-table my-order-table">
                            <tr className="order-table-line">
                            <td colSpan="2" className="my-order-status-td"><p className="my-order-status">Status: Enviado para transportadora</p></td>                   
                            </tr>
                            <tr className="order-table-line">
                                <td colSpan="2" className="my-order-td">
                                    <div className="my-order-status-bar"><div className="my-order-status-bar-progress bar-success"></div></div>
                                </td>
                            </tr>
                            <tr  >
                                <td className="order-td"><strong>5x</strong> miniquadros</td>
                                <td className="order-td"><strong>R$ 150,00</strong></td>
                            </tr>
                            <tr className="order-table-line">
                                <td className="order-td">Frete para cep  <strong>26540-100</strong></td>
                                <td className="order-td"><strong>R$ 23,05</strong></td>
                            </tr>
                            <tr className="order-table-line top-border">
                                <td className="order-td">Total da compra</td>
                                <td className="order-td"><strong>R$ 173,05</strong></td>
                            </tr>
                        </table>    
                        </div>

                    </div>
                </DefaultPanel>

                <DefaultPanel title={MyOrderTitle.title2}>
                    <div className="order-box my-order-box">
                        
                        <div className="order-col">
                            <div className="product-image"><span>Box product</span></div>
                            <Link to="/detail-order" className="button">Abrir pedido</Link>
                        </div>
                        <div className="order-col">
                        <table className="order-table my-order-table">
                            <tr className="order-table-line">
                            <td colSpan="2" className="my-order-td"><p className="my-order-status">Status: Pagamento Negado</p></td>                   
                            </tr>
                            <tr className="order-table-line">
                                <td colSpan="2" className="my-order-td">
                                <div className="my-order-status-bar"><div className="my-order-status-bar-progress bar-denied"></div></div>
                                </td>
                            </tr>
                            <tr  >
                                <td className="order-td"><strong>5x</strong> miniquadros</td>
                                <td className="order-td"><strong>R$ 150,00</strong></td>
                            </tr>
                            <tr className="order-table-line">
                                <td className="order-td">Frete para cep  <strong>26540-100</strong></td>
                                <td className="order-td"><strong>R$ 23,05</strong></td>
                            </tr>
                            <tr className="order-table-line top-border">
                                <td className="order-td">Total da compra</td>
                                <td className="order-td"><strong>R$ 173,05</strong></td>
                            </tr>
                        </table>    
                        </div>

                    </div>
                </DefaultPanel>


            </div>

            </MasterPage>
            
        );
    }
}

export default MyOrder;
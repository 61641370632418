import React, { Component } from "react";
import MasterPage from "../components/layout/MasterPage";

class NotFound extends Component {
  render() {
    return (
      <MasterPage>
        <div className="error-box">
          <div className="error-body text-center">
            <h1>404</h1>
            <h3 className="text-uppercase">Página não encontrada!</h3>
            <a href="/" className="btn btn-info btn-rounded waves-effect waves-light m-b-40">
              Voltar para a página inicial
            </a>
          </div>
        </div>
      </MasterPage>
    );
  }
}

export default NotFound;

import React, { Component } from "react";
import MasterPage from "../components/layout/MasterPage";

export default class DesignScene extends Component {

    render() {

        return <MasterPage>
            <div class="post-content">
                <h2 id="entrega" class="mb">Como os<br/>Miniquadros<br/>são elaborados?</h2>
                <div class="col-sm-12">
                    <div class="container">
                        <h3 id="pr-produ-o">Pré produção</h3>
                        <p>Tudo começa a partir de uma diagramação feitas com todas as fotos que você nos enviou através do aplicativo. Essa fase é necessária pois a para que o processo seja rápido, precisamos escolher a melhor forma de distribuir os arquivos para a máquina de impressão.</p><h3 id="produ-o-da-matriz">Produção da Matriz</h3><p>Depois de diagramadas, as fotos passam pela máquina de impressão, produzindo assim uma peça única que chamamos de matriz. A matriz contém as fotos que você nos enviou agregadas em um bloco, evitando
                        que se misture com as demais.</p><h3 id="controle-de-qualidade-de-impress-o">Controle de qualidade de impressão</h3><p>Ainda na fase de matriz, temos o nosso primeiro processo de controle de qualidade. Aqui são verificadas possíveis falhas no processo de colorização. Também é feita uma comparação com as fotos enviadas pelo aplicativo para assegurar que o produto que chegar em suas mãos seja exatamente como esperado.</p><h3 id="corte">Corte</h3><p>Após aprovação do controle de qualidade, as matrizes são enviadas para o corte. Aqui nasce cada quadro em seu formato bruto.
                        Cada matriz, ao passar pela máquina de corte, torna-se um grupo de quadros ainda sem acabamento.</p><h3 id="montagem">Montagem</h3><p>Realizado o corte, chegou a hora da montagem. Aqui são incluídos os materiais que fazem parte do nosso conhecido produto. Esses materiais são utilizados através de um padrão seguido rigorosamente durante este processo.</p><h3 id="controle-de-qualidade-de-montagem">Controle de qualidade de montagem</h3><p>Agora que os nossos quadros já se parecem com o produto final, é feita uma verificação do acabamento, alinhamento e estrutura. O pacote
                        agora está pronto para seguir em frente.</p><h3 id="embalagem-e-expedi-o">Embalagem e Expedição</h3><p>Os quadros agora são acondicionados em caixas para transporte. Nesse processo há também um padrão a ser usado para que os quadros, mesmo sendo feitos de um material leve e resistente, não sejam danificadas durante o transporte.</p></div>
                </div>
            </div>
        </MasterPage>
    }
}

import React, { Component } from "react";
import Header from '../Header';
import Footer from '../Footer';
import LoadingPanel from '../LoadingPanel';
import "../../assets/sass/app.scss";

class MasterPage extends Component {
    render = () => {
        return (
            <main className="app-container">
                <Header />
                <div className="container-content">
                    {
                        this.props.loading
                            ?
                            <section className="wrapper">
                                <LoadingPanel />
                            </section>
                            :
                            <section className={`wrapper ${(this.props.loading ? 'loading': '')}`}>{this.props.children}</section>
                    }
                </div>
                <Footer />
            </main>
        );
    };
}

export default MasterPage;

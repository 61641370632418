import React, { Component } from "react";
import InputMask from "react-input-mask";
import idGenerator from "react-id-generator";

const ENTER_KEY_CODE = 13;

class InputField extends Component {
	constructor(props) {
		super(props);

		const notImplementedValidation = v => {
			console.debug("InputField: Validation not implemented");
		};

		this.htmlId = "input_" + idGenerator();
		this.onChange = props.onChange;
		this.onSubmit = props.onSubmit;
		this.validate = props.validation || notImplementedValidation;
		this.state = {
			value: this._isOption()
				? props.checked
					? true
					: false
				: props.value || "",
			validationMessage: null
		};
	}

	_isOption() {
		const TYPE = this.props.type ? this.props.type : "text";
		return ["checkbox", "radio"].indexOf(TYPE) > -1;
	}

	_onChange(event) {
		if (this.onChange) {
			this.onChange(event.target.value);
		}

		let newState = {
			value: event.target.value
		};

		const validationResult = this.validate(event.target.value);
		if (validationResult) {
			if (!validationResult.valid) {
				newState.validationMessage = validationResult.message;
			} else {
				newState.validationMessage = null;
			}
		}

		this.setState(newState);
	}

	_onKeyDown(event) {
		if (event.keyCode === ENTER_KEY_CODE && this.onSubmit) {
			this.onSubmit(this.state.value);
		}
	}

	_onOptionChange(event) {
		if (this.onChange) {
			this.onChange(event.target.checked);
		}
		this.setState({
			value: event.target.checked
		});
	}

	render() {
		if (this._isOption()) {
			return (
				<label
					className="form-option-wrapper"
					style={{ display: "flex", alignItems: "center" }}
				>
					<InputMask
						type={this.props.type}
						className="form-input"
						ref={node => {
							this.input = node;
						}}
						placeholder={this.props.placeholder}
						readOnly={this.props.readonly}
						disabled={this.props.disabled}
						onChange={this._onOptionChange.bind(this)}
						checked={this.state.value}
						mask={this.props.mask}
						maskChar={this.props.maskChar}
						autoComplete={this.props.autoComplete}
					/>
					<span>{this.props.label}</span>
				</label>
			);
		} else {
			let classes = "form-input";
			if (this.state.validationMessage) {
				classes += " error";
			}
			return (
				<div className="form-input-wrapper">
					{this.props.label ? (
						<label htmlFor={this.htmlId} className="form-label">
							{this.props.label}
						</label>
					) : null}
					<InputMask
						type={this.props.type ? this.props.type : "text"}
						className={classes}
						id={this.htmlId}
						placeholder={this.props.placeholder}
						readOnly={this.props.readonly}
						disabled={this.props.disabled}
						maxLength={this.props.maxLength}
						minLength={this.props.minLength}
						value={this.state.value}
						onChange={this._onChange.bind(this)}
						onKeyDown={this._onKeyDown.bind(this)}
						mask={this.props.mask}
						maskChar={this.props.maskChar}
						autoComplete={this.props.autoComplete}
						rows={this.props.rows}
					/>
					{this.state.validationMessage ? (
						<span className="form-validation">
							{this.state.validationMessage}
						</span>
					) : null}
				</div>
			);
		}
	}
}

export default InputField;

import React, { Component } from 'react';
import MasterPage from "../components/layout/MasterPage";
import InputField from '../components/InputField';
import { Link } from "react-router-dom";

class Register extends Component {
    render() {
        return (
            <MasterPage>
                
                <h1 className="page-title">Registre-se</h1>
                <div className="login-box">
                    <div className="login-content">
                    <h3 className="register-title">Por favor, informe seu email abaixo:</h3>
                        <InputField label="Email" type="text" placeholder="joao@email.com" className="form-input login" />
                        <Link to="/register-personal-data" className="button">Prosseguir</Link>
                        </div>
                </div>
            </MasterPage>
            
        );
    }
}

export default Register;
export function toStringCurrency(value) {
	return value.toLocaleString("pt-BR", {
		currency: "BRL",
		currencyDisplay: "symbol",
		style: "currency",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
}

export function toStringPercentage(value, value2, decimals = 2) {
	let v = (value * 100) / value2;
	if (isNaN(v)) v = 0;
	return `${v.toFixed(decimals)}%`;
}
